<template>
  <div class="notifications">
    <i  @click="toggleNotifications" class="mdi mdi-bell-outline" style ="color:#fff; size: 25px;">
      <p v-if="unreadNotifications > 0" class="badge">{{ unreadNotifications }}</p>
    </i>
    <div v-show="showNotifications" class="notification-list scrollable">
      <div v-if="unreadNotifications > 0" class="notification-number">{{ `You have ${unreadNotifications} notifications` }}
      </div>
      <div :class="notification.read?'notification-read':'notification-item'" v-for="notification in notificationsList" :key="notification.id"
        @click="updateReadNotification(notification)">
        <div class="icon">
          <i class="mdi mdi-lan-disconnect" style="text-align: center; margin: auto"></i>
        </div>
        <div >
          <p class="text-alarm">{{ `${notification.tipo_evento} del pozo ${notification.name}` }}</p>
          <p class="text-date">{{ formatDate(notification.fecha_evento) }}</p>
        </div>
      </div>
      <div v-if="notificationCount === 0" class="no-notifications-message">
        No tienes notificaciones.
      </div>
      <div v-if="hasMoreNotifications" @click="loadMoreNotifications" class="notification-load">
        Load More Notifications...
      </div>
    </div>
  </div>
</template>

<script>
import useNotification from "../composable/useNotification";
import { getErrors } from "@/utils/errorsResponse";
import { toastMessage } from "@/utils/baseFunctions";
import { urlNewNotification } from "../uriHub";
import { webSocketClientAuth } from "@/utils/webSocketClient";
import { showToast } from "@/utils/baseFunctions";

const { getListNotifications } = useNotification();
const { updateNotification } = useNotification();

export default {
  data() {
    return {
      connectionWebsocket: null,
      notificationsList: new Set(),
      showNotifications: false,
      pageNumber: 1,
      pageSize: 3,
      notificationCount: 0,
      hasMoreNotifications: false,
      totalPages: 0,
      unreadNotifications: 0,
    };
  },
  methods: {
    toggleNotifications() {
      event.stopPropagation(); 
      this.showNotifications = !this.showNotifications;
    },

    closeMenu() {
      if(this.showNotifications && !event.target.closest(".notification-list")){
          this.showNotifications = false;
      }
    },

    formatDate(date) {
      const options = {
        day: "numeric",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
      };

      return new Date(date).toLocaleString("en-US", options);
    },
    async getNotifications() {
      const response = await getListNotifications(this.pageNumber, this.pageSize);
      if (response && response.status == 200) {
        this.notificationsList = response.data.notifications;
        this.notificationCount = response.data.notificationCount;
        this.unreadNotifications = response.data.unreadNotifications;
        this.totalPages = response.data.pages;
        if (response.data.pages > 1) {
          this.hasMoreNotifications = true
        }else{
          this.hasMoreNotifications = false
        }
      }
    },

    async loadMoreNotifications() {
      const objLoader = this.$loading.show();
      const response = await getListNotifications(this.pageNumber + 1, this.pageSize);
      this.notificationsList = this.notificationsList.concat(response.data.notifications)
      this.pageNumber++
      this.totalPages = response.data.pages;
      if (this.pageNumber == this.totalPages) {
        this.hasMoreNotifications = false
      }
      objLoader.hide();
    },


    async updateReadNotification(notification) {
      if(notification.read === false){
        const id_notification = notification.id
        let formData = new FormData();
        formData.append("id", id_notification);
        const objLoader = this.$loading.show();
        const response = await updateNotification(formData);
        if ([200, 204, 201].includes(response.status)) {
          if(this.pageNumber==this.totalPages){
            this.notificationsList = this.notificationsList.map(notification =>{
              if(notification.id == id_notification){
                return{...notification, read:true}
              }else{
                return notification
              }
            });
            this.unreadNotifications--;
          }
          else if (this.pageNumber==1 && this.totalPages>1) {
            await this.getNotifications()
          } else {
            await this.getUpdateNotification()
          }
        } else {
          if (response.statusText == "Bad Request") {
            this.objError = response.data;
          } else {
            this.$toast.error(getErrors(response.data), toastMessage());
          }
        }
        objLoader.hide();
      }
    },


    async signalRLastHorusHub() {
      if (!this.$store.getters.getDataUser) return;
      this.connectionWebsocket = await webSocketClientAuth(urlNewNotification);

      if (this.connectionWebsocket) {
        const request = {
          user_id: this.$store.getters.getDataUser?.id,
        };
        this.connectionWebsocket.onopen = async () => {
          await this.connectionWebsocket.emit(request);
        };

        this.connectionWebsocket.onmessage = async (message) => {
          let resultHub = null;
          try {
            resultHub = JSON.parse(message.data);
          } catch (e) {
            resultHub = message.data;
          }
          await this.getData(resultHub);
        };
      } else {
        showToast(
          this.connectionWebsocket,
          "Error establishing websocket connection"
        );
      }
    },

    async getUpdateNotification(){
      const response = await getListNotifications(1, this.pageSize*this.pageNumber);
      if (response && response.status == 200) {
        this.notificationsList = response.data.notifications;
        this.notificationCount = response.data.notificationCount;
        if (response.data.pages > 1) {
          this.hasMoreNotifications = true
        }else{
          this.hasMoreNotifications = false
        }
      }
    },

    async getData({ response }) {
      if (response && response.length > 0) {
        response.forEach(notificacion => {
          if (!this.notificationsList.some(existingNotificacion => existingNotificacion.id === notificacion.id || existingNotificacion.fecha_evento > notificacion.fecha_evento)) {
            this.notificationsList.unshift(notificacion);
            this.notificationCount++
            this.unreadNotifications++
            
          }
        });
      }
    },
  },

  beforeUnmount() {
            if (this.connectionWebsocket != null) {
                this.connectionWebsocket.close();
                }
        },

  mounted() {
    this.getNotifications();
    this.signalRLastHorusHub();
  },

  created (){
            document.addEventListener("click", this.closeMenu);
         },
  beforeDestroy() {
            document.removeEventListener("click", this.closeMenu);
  },


};
</script>


<style scoped>

@media screen and (max-width:450px) {
  .notification-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: #191C25;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #191C25;
  width: 225px;
  border-radius: 5px;
  margin-right: -25px;

}

}
.badge {
  color: white;
  text-align: center;
  margin: auto;
  position: absolute;
  top: -5px;
  right: -15px;
  border-radius: 50%;
  background-color: red;
  size: 25px;
}


.icon {
  display: flex;
  background-color: #0D0D0D;
  min-width: 40px ;
  height: 40px;
  border-radius: 50%;
  margin: 5px;
  align-items: center;
}

.notification-alarm {
  display: flex;

}

.notification-icon {
  display: flex;
  cursor: pointer;
  background-color: #4174BC;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  justify-content: center;
  border: 1px solid #1B2E4F;

}

.notification-item {
  display: flex;
  border-top: 1px solid #2C2F36;
  padding-top: 5px;
}

.notification-read {
  display: flex;
  border-top: 1px solid #2C2F36;
  padding-top: 5px;
  background: #343434;
}

.notification-item:hover {
  background-color: #14151A;
  cursor: pointer;
}

.notification-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: #191C25;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #191C25;
  width: 300px;
  border-radius: 5px;

}

.notification-load {
  text-align: center;
  padding: 0.5rem;
  border-top: 1px solid #2C2F36;
}

.notification-load:hover {
  background-color: #14151A;
  cursor: pointer;
}

.notification-number {
  text-align: center;
  padding: 0.5rem;

}

.notifications {
  position: relative;

}

.no-notifications-message {
  font-size: medium;
  text-align: center;
  padding: 10px;
  margin: 10px 0;
}

.scrollable {
  max-height: 400px;
  overflow-y: auto;
}

.text-alarm{
  margin: 5px 0;
}


.text-date {
  font-size: small;
  color: gray;
  margin: 5px 0;
}
</style>