/**
 * @description route drillSage
 */
import { prefix } from "@/config/prefix"
const pre = prefix
const pathDrillSage = '/drillsage/'
    /**
     * @description route RealTime
     */
const routes = [{
    path: pre + pathDrillSage,
    name: 'DrillSage',
    component: () =>
        import ( /* webpackChunkName: "drillSage" */ '../ui/DrillSage.vue'),
    title: 'DrillSage',
    meta: { requireAuth: true },
}, ]

export default routes