import { apiClientAuth, apiClient, apiClientAuthFile, apiClientAuthStrata } from '@/config/axios'

/**
 * @description get method for api queries
 * @param {String} url get type api url
 * @param {Object} params object to be consulted by means of the api
 * @return {Object}
 */
export const getHttp = async function(url, params = null) {
    return await apiClientAuth.get(url, { params }).then(response => {
        return response
    }).catch(error => {
        return error?.response ?? error
    });
}


/**
 * @description post method for sending data with authentication token
 * @param {String} url post type api url
 * @param {Object} body object to be consulted by means of the api
 * @return {Object}
 */
export const postHttp = async function(url, body) {
    return await apiClientAuth.post(url, body).then(response => {
        return response
    }).catch(error => {
        return error?.response ?? error
    });
}

/**
 * @description put method for sending data with authentication token
 * @param {String} url post type api url
 * @param {Object} body object to be consulted by means of the api
 * @return {Object}
 */
export const putHttp = async function(url, body) {
    return await apiClientAuth.put(url, body).then(response => {
        return response
    }).catch(error => {
        return error?.response ?? error
    });
}

/**
 * @description http deletion method
 * @param {String} url post type api url
 * @param {Object} body object to be consulted by means of the api
 * @return {Object}
 */
export const deleteHttp = async function(url) {
    return await apiClientAuth.delete(url).then(response => {
        return response
    }).catch(error => {
        return error?.response ?? error
    });
}


/**
 * @description post method for sending data with authentication token
 * @param {String} url post type api url
 * @param {Object} body object to be consulted by means of the api
 * @return {Object}
 */
export const postHttpNotSecure = async function(url, body) {
    return await apiClient.post(url, body, { withCredentials: true }).then(response => {
        return response
    }).catch(error => {
        return error?.response ?? error
    });
}


/**
 * @description post method for sending data with authentication token
 * @param {String} url post type api url
 * @param {Object} body object to be consulted by means of the api
 * @return {Object}
 */
export const postHttpForm = async function(url, body) {
    return await apiClientAuth.postForm(url, body).then(response => {
        return response
    }).catch(error => {
        return error?.response ?? error
    });
}

export const postHttpAuthFile = async function(url, body) {
    return await apiClientAuthFile.post(url, body).then(response =>{
        return response
    }).catch(error => {
        return error?.response ?? error
    });
}

export const postHttpFormAuthFile = async function (url, body) {
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    return await apiClientAuthFile.post(url, body,{headers}).then(response =>{
        return response
    }).catch(error => {
        return error?.response ?? error
    });
}


export const getHttpAuthFile = async function(url, body) {
    return await apiClientAuthFile.get(url, body).then(response =>{
        return response
    }).catch(error => {
        return error?.response ?? error
    });
}

export const getHttpAuth = async function(url, params=null) {
    return await apiClientAuthStrata.get(url, {params}).then(response =>{
        return response.data
    }).catch(error => {
        return error?.response ?? error
    });
}