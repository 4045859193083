import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
/**
 * @property Import Style and Icons
 */
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@mdi/font/css/materialdesignicons.min.css"
//import './config/vueselect'
/**
 * @property import Slides
 */
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
/**
 * @property import Validation Components for form
 */
import { Field as VeeField, Form as VeeForm, ErrorMessage } from "vee-validate";

/**
 * @property import VueLoading Configuration
 */
// Import component
import VueLoading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
/**
 * @property import Validation Components for form
 */
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "echarts-gl";
import VueApexCharts from 'vue-apexcharts';
import { Chart } from "highcharts-vue";
// import * as Highcharts from "highcharts/highcharts";

// import "highcharts/modules/accessibility"

import * as Highcharts from "highcharts/highcharts";
import * as HighchartsPolar from "highcharts/highcharts-more";
import 'highcharts/themes/dark-unica.js'

//import Highcharts from "highcharts";
import accessibility from "highcharts/modules/accessibility";
accessibility(Highcharts);
import "highcharts/modules/exporting.js"
import "highcharts/modules/export-data.js"
import ToastPlugin from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-sugar.css';
import VueGates from 'vue-gates';

import criptoJs from 'crypto-js'

HighchartsPolar(Highcharts);
/**
 * @description VueSweetalert2 configuration
 */
const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
    customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
    reverseButtons: true,
};

import 'devextreme/dist/css/dx.dark.css';

import { DxDataGrid, DxSearchPanel, DxPager, DxPaging, DxEditing, DxHeaderFilter, DxColumn, DxButton, DxExport, DxSelection } from 'devextreme-vue/data-grid';
const app = createApp(App);
app.use(store);
app.use(router);
//app.use(VueGates);
app.use(criptoJs);
app.use(VueGates, { persistent: true });
app.component("DxPager", DxPager);
app.component("DxExport", DxExport);
app.component("DxPaging", DxPaging);
app.component("DxDataGrid", DxDataGrid);
app.component("DxEditing", DxEditing);
app.component("DxHeaderFilter", DxHeaderFilter);
app.component("DxSearchPanel", DxSearchPanel);
app.component("DxButton", DxButton);
app.component("DxColumn", DxColumn);
app.component("DxSelection", DxSelection);
app.component('highCharts', Chart);
app.component('apexChart', VueApexCharts);
/**
 * @description Slides configuration
 */
app.component("vueper-slides", VueperSlides);
app.component("vueper-slide", VueperSlide);
/**
 * @description Form Validate configuration
 */
app.component("VeeField", VeeField);
app.component("VeeForm", VeeForm);
app.component("ErrorMessage", ErrorMessage);
/**
 * @description component select
 */
// app.component("v-select", VueSelect);
import vSelect from "vue-select";
app.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

/**
 * @description Loading configuration
 */
app.use(VueLoading, {
    backgroundColor: 'black',
    color: '#53a8d9',
    loader: 'dots',
    opacity: 0.5,
});

/**
 * @description Sweetalert2 configuration
 */
app.use(VueSweetalert2, options);
app.use(ToastPlugin);
app.config.productionTip = false
    //app.provide('$crypto', criptoJs)



app.mount('#app');