import { apiWellGetAll, apiWellGetAllParams, apiWellAssigCompany, apiWellAssigUser, apiWellGetCompanyId, apiGetFavoriteWells,deleteFavoriteWell,addFavoriteWell} from '../uriApi'
import { getHttp, postHttp } from '@/utils/http'

/**
 * @description
 * @returns { Functions }
 */
const useWell = () => {

    /**
     * @description
     * @returns { Object } api response
     */
    const getAllWell = async() => {
        const response = await getHttp(apiWellGetAll)
        return response
    }
    const getAllWellParams = async(search) => {
        const response = await getHttp(apiWellGetAllParams + search)
        return response
    }

    const getWellByIdCompany = async(arrayCompanyIds) => {

        let params = new URLSearchParams();
        for (let i of arrayCompanyIds) {
            params.append('company', i);
        }
        const response = await getHttp(apiWellGetCompanyId + '?' + params.toString())
        return response
    }


    const wellAssignmentCompany = async(data) => {
        const response = await postHttp(apiWellAssigCompany, data)
        return response
    }

    const wellAssignmentUser = async(data) => {
        const response = await postHttp(apiWellAssigUser, data)
        return response
    }

    const getFavoriteWells = async()=>{
        const response = await getHttp(apiGetFavoriteWells)
        return response
    }

    const deleteFavoriteWells = async(data)=>{
        const response = await postHttp(deleteFavoriteWell,data)
        return response
    }

    const addFavoriteWells = async(data)=>{
        const response = await postHttp(addFavoriteWell,data)
        return response
    }

    return {
        getAllWell,
        getAllWellParams,
        getWellByIdCompany,
        wellAssignmentCompany,
        wellAssignmentUser,
        getFavoriteWells,
        deleteFavoriteWells,
        addFavoriteWells
    }
}

export default useWell