/** 
 * @description route Home
 */
const pathHome = '/home'
const routes = [{
    path: pathHome,
    name: 'HomeView',
    component: () =>
        import ( /* webpackChunkName: "HomeView" */ '../ui/Home.vue'),
    title: 'HomeView',
}, ]

export default routes