<template>
    <div>
        <div id="nav-public1" class="navigation" style=" width: 100% !important; position: fixed; z-index: 99; top: 0;">
            
            <navigation></navigation>
           
        </div>
        <div class="main-panel-p">
            <router-view></router-view>
        </div>
        <footerbg />
    </div>
</template>

<script>
/**
 * @property import libraries
 */
import navigation from "../components/navbar/Navigation.vue";
import footerbg from "../components/footer/FooterView.vue";
export default {
    /**
     * @property Component name
     */
    name: "AppLayoutPublic",

    /**
     * @property component initialization
     */
    components: {
        navigation,
        footerbg,
    },

    /**
     * @property component methods
     */
    methods: {
        activeClass() {
            window.addEventListener("scroll", function () {
                let scrollY = this.scrollY;
                var div = document.getElementById("nav-public1");
                if (!div) return;

                if (scrollY > 10) {
                    div.classList.add("active");
                } else {
                    div.classList.remove("active");
                }
            });
        },
    },
    mounted() {
        this.activeClass();
    },
};
</script>
<style scoped>
.main-panel-p {
    margin-top: 4.8rem;
}
.navigation {
    background-image: url("@/assets/pictures/background.jpg") !important;
    box-shadow: 1px 2px 10px rgba(55, 91, 112, 0.918) !important;
}
.navigation.active {
    background-image: url("@/assets/pictures/background.jpg") !important;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1) !important;
}
</style>
