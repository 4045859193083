import { prefix } from '@/config/prefix';

const routes = [{
    path: `${prefix}/gas-evaluation/:well_selected`,
    name: 'TabGasEvaluation',
    component: () =>
        import ('../ui/TabGasEvaluation.vue'),
    meta: { requireAuth: true },
}, ];

export default routes;