/** 
 * @description route About
 */
const pathContact = '/contact'
const routes = [{
    path: pathContact,
    name: 'ContactView',
    component: () =>
        import ( /* webpackChunkName: "ContactView" */ '../ui/Contact.vue'),
    title: 'ContactView',
}, ]

export default routes