import axios from 'axios'
import store from '../store'
import router from '../router'
import jwt_decode from "jwt-decode";
import dayjs from 'dayjs'
/**
 * @description axios baseURL
 */
const API = process.env.VUE_APP_ROOT_API
const API_STRATA = process.env.VUE_APP_ROOT_API_STRATA


// Fn reutilizable para el interceptor
const authInterceptor = async(req) => {
    const user = jwt_decode(store.getters.getAccessToken);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    const authorizationToken = isExpired ? store.getters.getRefreshToken : store.getters.getAccessToken;
    req.headers.Authorization = `Bearer ${authorizationToken}`;
    return req;
};

/**
 * @description axios instance for requests with auth token
 */
const apiClientAuth = axios.create({
    baseURL: API,
    // headers: { Authorization: `Bearer ${store.getters.getAccessToken}` },

    validateStatus: (status) => {
        if (status == 401) {
            store.dispatch('storeAuthenticateDataUser', {})
            store.dispatch('readToken', null)
            store.dispatch('refreshToken', null)
            store.dispatch('storeObjPermissions', {})
            localStorage.clear();
            router.push({ name: "LoginView" })
        }
        return status >= 200 && status < 300; // default
    },
})

const apiClient = axios.create({
    baseURL: API,
})

const apiClientAuthFile = axios.create({
    baseURL: API,
    // headers: { Authorization: `Bearer ${store.getters.getAccessToken}` },

    validateStatus: (status) => {
        if (status == 401) {
            store.dispatch('storeAuthenticateDataUser', {})
            store.dispatch('readToken', null)
            store.dispatch('refreshToken', null)
            store.dispatch('storeObjPermissions', {})
            localStorage.clear();
            router.push({ name: "LoginView" })
        }
        return status >= 200 && status < 300; // default
    },

    responseType: 'blob',
})

const apiClientAuthStrata = axios.create({
    baseURL: API_STRATA,
})

apiClientAuth.interceptors.request.use(authInterceptor);
apiClientAuthFile.interceptors.request.use(authInterceptor);
apiClientAuth.interceptors.request.use(authInterceptor);
/*apiClientAuth.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 403) {
        // Si la API responde con un código 403, redirigir a la vista de AppLayoutView
        router.push({ name: 'AppLayoutView' });
      }
      return Promise.reject(error);
    }
  );*/

export { apiClientAuth, apiClient, apiClientAuthFile, apiClientAuthStrata };