/** 
 * @description route Roles and Permissions Config
 */
const pathRolePerm = '/role-permissions'
const routes = [{
    path: pathRolePerm,
    name: 'AdministrationView',
    component: () =>
        import ( /* webpackChunkName: "AdministrationView" */ '../ui/Administration.vue'),
    title: 'AdministrationView',
    meta: {
        requireAuth: true,
        roles: [],
        permissions: []
    }
}, ]

export default routes