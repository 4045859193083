export default {
    /**
     * @description
     * @param { Any } state reference to the objWellSelect object of the store
     * @param { Any } payload object to receive
     */
    setObjFormationLogForm(state, { key, payload }) {
        if (payload == null) {
          state.objFormationLogForm[key] = {
            arrDataSource: [],
            arrMnemonicChroma: [],
            arrMnemonicLwd: [],
            arrMnImported: [],
            arrMnOperationals: [],
            arrSensorChroma: [],
            arrSensorLwd: [],
          };
        } else {
          state.objFormationLogForm[key] = payload;
        }
      },
};