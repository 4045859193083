import { apiCompany, apiCompanyUser, apiGetCompany, apiGetTypeCompany, apiGetTypeParent, apiUpdateCompany } from '../uriApi'
import { getHttp, postHttp, deleteHttp, putHttp } from '@/utils/http'

/**
 * @description
 * @returns { Functions }
 */
const useCompany = () => {
    /**
     * @description
     * @returns { Object } api response
     */
    const registerCompany = async(dataCompany) => {
        const response = await postHttp(apiCompanyUser, dataCompany)
        return response
    }

    /**
     * @description
     * @returns { Object } api response
     */
    const getAllCompany = async() => {
        const response = await getHttp(apiGetCompany)
        return response
    }

    const getAllWithMyCompany = async() => {
        const response = await getHttp(apiGetCompany + '?show_mycompany')
        return response
    }

    const getTypeCompany = async() => {
        const response = await getHttp(apiGetTypeCompany)
        return response
    }

    /**
     * @description Updates the status of the channel, destructuring the object that is received by parameter
     * @param { Integer } id of the channel to be updated
     * @returns petition response
     */
    const getTypeParent = async(parentId) => {
        const response = await getHttp(apiGetTypeParent + parentId)
        return response
    }

    const deleteCompany = async(idCompany) => {
        const response = await deleteHttp(apiCompany + "/" + idCompany)
        return response
    }

    const updateCompany = async(id_company, dataCompany) => {
        const response = await putHttp(`${apiUpdateCompany}${id_company}`, dataCompany)
        return response
    }


    return {
        deleteCompany,
        getAllCompany,
        getAllWithMyCompany,
        getTypeCompany,
        getTypeParent,
        registerCompany,
        updateCompany,
    }
}

export default useCompany