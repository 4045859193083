
const pathWell = '/assign-well-to-users'

const routes = [{
    path: pathWell,
    name: 'AssignWellToUsers',
    component: () =>
        import ( /* webpackChunkName: "ListUsersByWells" */ '../ui/ListUsersByWells.vue'),
    title: 'AssignWellToUsers',
    meta: {
        requireAuth: true,
        roles: [],
        permissions: []
    }
}, ]

export default routes