/** 
 * @description route Services
 */
const pathServices = '/services'
const routes = [{
    path: pathServices,
    name: 'ServicesView',
    component: () =>
        import ( /* webpackChunkName: "ServicesView" */ '../ui/Services.vue'),
    title: 'ServicesView',
}, ]

export default routes