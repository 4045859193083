/** 
 * @description route Reset-Password
 */
const pathResetPass = '/reset-password'
const routes = [{
    path: pathResetPass,
    name: 'ResetPassword',
    component: () =>
        import ( /* webpackChunkName: "ResetPassword" */ '../ui/ResetPassword.vue'),
    title: 'ResetPassword',
    meta: {
        requireAuth: true,
    }
}, ]

export default routes