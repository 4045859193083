<template>
  <div>
      <footer class="footer text-center text-lg-start text-muted mt-2">
        <div class="text-center justify-content-center">
          <div class="info-social-media justify-content-center">
            <div class="p-2 text-center">
              <i class="icon-nav mdi mdi-youtube" style="color:#4a8fb8"></i>
            </div>
            <div class="p-2 text-center">
              <i class="icon-nav mdi mdi-linkedin" style="color:#4a8fb8"></i>
            </div>
            <div class="p-2 text-center">
              <i class="icon-nav mdi mdi-whatsapp" style="color:#4a8fb8"></i>
            </div>
          </div>
        </div>
        <div class="text-center">
          <img src="@/assets/pictures/idlink1.png" class="" style="max-width: 5rem" alt="">
        </div>
        <div class="text-center">
          <label style="font-size: 1.2rem">Copyright © {{ obtenerAnioActual() }} Intelligent Reservoir &nbsp; </label>
        </div>
        <div class="text-center justify-content-center">
          <img src="@/assets/pictures/footer/footerf.jpg" class="imagenmundo" alt="">
        </div>
        <!-- Copyright -->
      </footer>
      
  </div>
</template>
<script>
  export default {
    /**
     * @property component name
     */
    name: "FooterView",
    components: {
      
    },
    methods: {
      openForm() {
        document.getElementById("myForm").style.display = "block";
      },
      obtenerAnioActual() {
              const fecha = new Date();
              const anio = fecha.getFullYear();
              return anio;
          }
    },
  };
</script>

<style>
  #foot {
    background-color: black;
}
.imagenmundo {
  position:relative;
  z-index:1;
  width:100%;
  height:100%;
}
.info-social-media {
    display: flex;
}

.icon-nav {
    font-size: 2rem;
    color: white;
}

</style>