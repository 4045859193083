import { createRouter, createWebHistory } from 'vue-router'
/**
 * @description url prefix
 */
import { prefix } from "@/config/prefix"
/**
 * @description Path not found
 */
import PathNotFound from '../PathNotFound.vue'
/**
 * @description Views
 */
import AppLayoutPublic from '../views/AppLayoutPublic.vue'
import Home from '../views/home/router'
import About from '../views/about/router'
import Services from '../views/services/router'
import Solutions from '../views/solutions/router'
import Contact from '../views/contact/router'
import Login from '../modules/auth/login/router'
import ForgotPass from "../modules/auth/forgotPassword/router"
/**
 * @description Modules
 */
import ResetPass from "../modules/auth/resetPassword/router"
import menuOptions from "../modules/menu/router"

/**
 * @description Modules Admin Idlink
 */
import Company from "../modules/administration/company/router"
import User from "../modules/administration/user/router"
import AssignWellCompany from "../modules/administration/assignWellCompany/router"
import AssignWellUsers from "../modules/administration/assignWellUsers/router"
import RolesPermissions from "../modules/administration/rolesPermissions/router"
import fileManagement from "../modules/strataId/fileManagement/router"
import configurationVariables from "../modules/strataId/ConfigVariables/router"
import drillSage from "../modules/strataId/DrillSage/router"


//import { roles } from '../config/ConfigRol'

/**
 * @description Modules Views
 */
import AppLayoutView from '../AppLayoutView.vue'


import store from "../store";

const pre = prefix;
/**
 * @description routes App
 */
const routes = [
    { path: '/:pathMatch(.*)*', name: 'PathNotFound', component: PathNotFound },
    {
        path: "/",
        redirect: '/home',
        name: 'AppLayoutPublic',
        component: AppLayoutPublic,
        children: [
            ...Home,
            ...About,
            ...Services,
            ...Solutions,
            ...Contact,
            ...ForgotPass,
            ...Login,
        ],
    },
    {
        path: pre,
        name: 'AppLayoutView',
        meta: {
            requireAuth: true,
            roles: ['OWNER_SRV', ],
            permissions: []
        },
        component: AppLayoutView,
        children: [
            ...Company,
            ...User,
            ...AssignWellCompany,
            ...AssignWellUsers,
            ...RolesPermissions,
            ...ResetPass,
            ...menuOptions,
            ...fileManagement,
            ...configurationVariables,
            ...drillSage,
        ],
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

/**
 * @description beforeEach, query the token-auth protected
 * @param { Object } to Route: the target Route Object being navigated to.
 * @param { Object } from the current route being navigated away from.
 * @param {Function} next this function must be called to resolve the hook. The action depends on the arguments provided to next
 */
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    const routeAuth = to.matched.some((record) => record.meta.requireAuth);
    //si routeAuth es true y  getAccessToken es null user not auth // else user auth
    //routeAuth && store.getters.getAccessToken === null ? next({ path: "/loginView" }) : next();
    if (routeAuth && store.getters.getAccessToken === null) {
        next({ name: "LoginView" })
    } else {
        if (to.meta.permissions && to.meta.permissions.length > 0) {
            const is_next = to.meta.permissions.some((permission) => {
                let hasPermission = store.getters.getObjPermissions.filter(e => e.archetype === permission)
                return hasPermission.length
            });

            //Si no tiene permiso redireciona a AppLayoutView
            is_next ? next() : next({ name: "AppLayoutView" })
        } else {
            next()
        }
    }
});

router.beforeEach((to, from, next) => {
    //Replece regex, separates CamelCase string in space
    let routeName = to.name
    document.title = `${ process.env.VUE_APP_TITLE } - ${ routeName != 'AppLayoutView' && routeName != undefined ? routeName.replace(/([a-z0-9])([A-Z])/g, '$1 $2') : 'Home' }`
    next()
})


export default router