export default {
    /**
     * @description commit to the mutation setObjChartsML
     * @param { Object } state reference to the mutation setObjChartsML
     * @param { Object } payload
     */
    storeWellInformation: function(state, payload) {
        state.commit('setWellInformation', payload)
    },
    storeObjChartsML: function(state, payload) {
        state.commit("setObjChartsML", payload);
    },
};