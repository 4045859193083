import { apiDeleteUser, apiUpdateUser, apiCreateUser, apiUsers } from '../uriApi'
import { deleteHttp, postHttp, putHttp, getHttp } from '@/utils/http'

/**
 * @description
 * @returns { Functions }
 */
const useUser = () => {

    /**
     * @description
     * @returns { Object } api response
     */
    const deleteUserById = async(idUser) => {
        const response = await deleteHttp(`${apiDeleteUser}${idUser}`)
        return response
    }

    const updateUser = async (idUser, request) => {
        const response = await putHttp(`${apiUpdateUser}${idUser}`, request)
        return response
    }

    const createUser = async(request) => {
        const response = await postHttp(apiCreateUser, request)
        return response
    }
    const listUserbyCompany = async(companyId) => {
        const response = await getHttp(apiUsers + '/' + companyId)
        return response
    }

    const getUserById = async(idUser) => {
        const response = await getHttp(`${apiUsers}/${idUser}`)
        return response
    }

    return {
        createUser,
        deleteUserById,
        getUserById,
        listUserbyCompany,
        updateUser,
    }
}

export default useUser