<template>
    <div>
        <li class="nav-item dropdown">
            <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                <div class="navbar-profile">
                    <img class="img-xs rounded-circle" :src="srtSetIMG" />
                    <label class="mb-0 d-none d-sm-block navbar-profile-name small text-muted">
                        {{ $store.getters.getDataUser.email }}
                    </label>
                    <!-- <i class="mdi mdi-menu-down d-none d-sm-block"></i> -->
                </div>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                <h6 class="p-3 mb-0">Notifications</h6>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                    <div class="preview-thumbnail">
                        <div class="preview-icon bg-dark rounded-circle">
                            <i class="mdi mdi-calendar text-success"></i>
                        </div>
                    </div>
                    <div class="preview-item-content">
                        <p class="preview-subject mb-1">Event today</p>
                        <p class="text-muted ellipsis mb-0">
                            Just a reminder that you have an event today
                        </p>
                    </div>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                    <div class="preview-thumbnail">
                        <div class="preview-icon bg-dark rounded-circle">
                            <i class="mdi mdi-settings text-danger"></i>
                        </div>
                    </div>
                    <div class="preview-item-content">
                        <p class="preview-subject mb-1">Settings</p>
                        <p class="text-muted ellipsis mb-0">Update dashboard</p>
                    </div>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                    <div class="preview-thumbnail">
                        <div class="preview-icon bg-dark rounded-circle">
                            <i class="mdi mdi-link-variant text-warning"></i>
                        </div>
                    </div>
                    <div class="preview-item-content">
                        <p class="preview-subject mb-1">Launch Admin</p>
                        <p class="text-muted ellipsis mb-0">New admin wow!</p>
                    </div>
                </a>
                <div class="dropdown-divider"></div>
                <p class="p-3 mb-0 text-center">See all notifications</p>
            </div>
        </li>
    </div>
</template>

<script>
    // import { setSwallWarning } from "@/utils/baseFunctions";
    import { getImgUser } from "@/utils/imgFuntionsj";
    export default {
        /**
         * @property component name
         */
        name: "DropdownNavbar",
        /**
         * @property data, initialization of component variables
         */

        data() {
            return {
                srtSetIMG: "",
            };
        },
        /**
         * @property component methods
         */
        methods: {
            async loadImgUser() {
                this.srtSetIMG = await getImgUser();
            },
            /**
             * @description removes the user's authentication token and logs the user out.
             */
            // logout: function () {
            //     this.$swal(setSwallWarning(`<h4>¿Confirm Log Out?</h4>`, "")).then((result) => {
            //         if (result.isConfirmed) {
            //             //localStorage.removeItem("keyToken");
            //             localStorage.clear();
            //             setTimeout(
            //                 () =>
            //                     this.$router.push({
            //                         name: "Login",
            //                     }),
            //                 1000
            //             );
            //         }
            //     });
            // },
        },
        mounted: function () {
            this.loadImgUser();
        },
    };
</script>

<style scoped>
    .navpicture {
        max-width: 120%;
        max-height: 120%;
        border-radius: 100%;
    }

    .navbar__email {
        font-size: 0.8em;
    }
</style>
