import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    /**
     * @property state, initialization of store variables
     */
    state: {
        chartsML: {
            chart_1: [],
            chart_2: [],
            chart_3: [],
            chart_4: [],
            chart_5: [],
            WellInformation: null,
        }
    },
    actions,
    getters,
    mutations,
};