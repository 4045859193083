<template>
    <div class="size-map " style="position:relative">
        <div v-if="bolIconFilter" style="position: absolute;
        right: 20px;
        top: 0.2em;
        z-index: 5;">
            <button @click="filtershow()" type="button" class="btn btn-filter btn-sm "><i class="mdi mdi-filter"
                    Style="color:#4a90b9"></i></button>
        </div>
        <div v-if="bolFilter">
            <div class="content-filter-map">
                <div style="text-align: left;">
                    <div class="" style="background: #4a90b9; width: 100%;">
                        <label class="p-2">Filter</label>
                        <button @click="getFavoriteWell()" class="btn icon-favorite">
                            <i class="mdi mdi-star"
                                :class="[showFavorite ? 'icon-favorite-selected' : 'icon-favorite-deselected']"></i></button>
                        <button @click="filterhide()" type="button" class="btn btn-sm btn-close-filter "><i
                                class="mdi mdi-filter-remove" Style="color:black"></i></button>
                    </div>
                    <div v-permission:any="'_viewer_well_all_company'" v-if="arrCompany.length" class="mt-3 p-1">
                        <label for="well">Company </label>
                        <v-select multiple v-model="companyId" :reduce="as => as.id" :options="arrCompany"
                            :getOptionLabel="as => as.name" id="name">
                        </v-select>
                    </div>
                    <div class="mt-2 p-1">
                        <label for="well">Well</label>
                        <v-select v-model="wellsSelect" 
                            :value="arrayWell.id" 
                            :options="arrayWell" 
                            :filterable="false"
                            :getOptionLabel="as => as.operating_company + '|' + as.well_name" id="well_name" 
                            :clearSearchOnBlur="false" 
                            :clearable="false"
                            :loading = "isloading"
                            @open="onOpen"
                            @close="onClose" 
                            @keydown="preventTypingDuringLoading"
                            @search="debouncedSearch"
                            class="custom-select"
                           >
                            <template #list-footer>
                                <li v-show="hasNextPage" ref="load" class="loader">
                                    Loading more options...
                                </li>
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row size-map">
            <div class="col-12 size-map">
                <div class="size-map" style="position: relative">
                    <div class="amcharts-map" id="chartdiv"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldHigh";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import "@amcharts/amcharts4/themes/animated";
import { toastMessage } from "@/utils/baseFunctions";
//import { apiWellGetAll } from "../../administration/wells/uriApi";
import { getHttp } from "@/utils/http";

import useCompany from "../../administration/company/composable/useCompany";
const { getAllCompany } = useCompany()

import useWell from "../../administration/wells/composable/useWell";
const { getWellByIdCompany, getAllWellParams, getFavoriteWells, addFavoriteWells, deleteFavoriteWells } = useWell()
import { apiWellGetAllParams } from '@/modules/administration/wells/uriApi'

import { roleOwner } from '../../administration/rolesPermissions/params/roles'

import criptoJs from 'crypto-js'

export default {

    name: "MapAdmin",

    data() {
        return {
            arrayWell: [],
            companyId: [],
            pozo1: "",
            arrCompany: [],
            objParams: {
                company_name: "",
                well_name: "",
            },
            idWell: 0,
            bolFilter: false,
            bolIconFilter: true,
            wellsSelect: [],
            roleOwner,
            page: 1,
            perPage: 20,
            arrayData: [],
            observer: null,
            showFavorite: false,
            searchTimeout: null,  
            isloading:false,
        };
    },

    components: {
    },
    prop: {
        SelectidWell: 0,
        SelectpcId: 0,
    },

    watch: {
        companyId: {
            deep: true,
            handler() {
                this.companyId.length == 0 || !this.companyId ? this.getChangeStatus() : this.getWellByCompanyId()
            }
        },
        wellsSelect: {
            deep: true,
            handler() {
                // const dataMap = [];
                const wellInfo = {
                    pc_id: this.wellsSelect.id_pc,
                    well_id: this.wellsSelect.id_well,
                    units_of_measurement: this.wellsSelect.units_of_measurement,
                }
                console.log("WELLINFO",wellInfo)
                console.log("COMPANYID",this.companyId,this.arrCompany)
                if(wellInfo.pc_id){
                    this.onSelectInput(wellInfo)
                // dataMap.push(this.wellsSelect);
                // // dataMap.push(this.companyId);
                // dataMap.length == 0 || !this.wellsSelect ? this.mapDash(this.arrayWell) : this.mapDash(dataMap)
                    this.filterhide()
                }
            }
        },


    },

    computed: {
        hasNextPage() {
            return this.page * this.perPage < this.arrayWell.length;
        },

    },


    methods: {

        debouncedSearch(searchTerm) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.arrayWell = []
                this.getWellAll(searchTerm);  
            }, 700);  
        },


        groupDataByDistance(data, zoomLevel) {

            const zoomToRadiusRatio = 5; // Define el factor de escala entre el nivel de zoom y el radio
            
            const groupedCentroids = [];
            const groupedData = [];

            // Calcula el radio de agrupamiento en función del nivel de zoom
            const radius = zoomToRadiusRatio/zoomLevel;
            if (zoomLevel>13){
                for( let i = 0; i < data.length; i++){
                    data[i].grouped = false
                    return data
                }
            }
            for (let i = 0; i < data.length; i++) {
                const currentPoint = data[i];
                let isGrouped = false;

                // Busca si el punto actual puede agruparse con alguno de los grupos existentes
                for (let j = 0; j < groupedData.length; j++) {
                    const group = groupedData[j];
                    const groupCenter = group.reduce((acc, point) => {
                        return {
                            latitude: acc.latitude + point.latitude,
                            longitude: acc.longitude + point.longitude
                        };
                    }, { latitude: 0, longitude: 0 });
                    groupCenter.latitude /= group.length;
                    groupCenter.longitude /= group.length;

                    // Calcula la distancia euclidiana entre el punto actual y el centro del grupo
                    const distance = Math.sqrt(
                        Math.pow(currentPoint.latitude - groupCenter.latitude, 2) +
                        Math.pow(currentPoint.longitude - groupCenter.longitude, 2)
                    );

                    // Si el punto está dentro del radio, agrégalo al grupo y marca como agrupado
                    if (distance <= radius) {
                        group.push(currentPoint);
                        isGrouped = true;
                        break;
                    }
                }

                // Si el punto no se agrupó con ninguno de los grupos existentes, crea un nuevo grupo
                if (!isGrouped) {
                    groupedData.push([currentPoint]);
                }
            }

            // Calcular los centroides de cada grupo y almacenarlos en el arreglo de centroides
            for (let i = 0; i < groupedData.length; i++) {
                const group = groupedData[i];
                const groupCenter = this.calculateCentroid(group);
                groupedCentroids.push(groupCenter);
            }
            

            return groupedCentroids;
        },


        calculateCentroid(group) {

            if (group.length === 1){
                group[0].grouped = false
                return group[0]
            }
            // Inicializar las sumas de latitud y longitud
            let sumLat = 0;
            let sumLon = 0;

            // Calcular la suma de todas las latitudes y longitudes
            for (let i = 0; i < group.length; i++) {
                sumLat += group[i].latitude;
                sumLon += group[i].longitude;
                
            }

            // Calcular el punto medio dividiendo las sumas por el número de puntos
            const centroidLat = sumLat / group.length;
            const centroidLon = sumLon / group.length;

            // Devolver el punto medio como un objeto
            return { 
                grouped: group.length>1 ? true : false,
                size:group.length,
                latitude: centroidLat, 
                longitude: centroidLon 
            };
        },

        onSelectInput(wellInfo) {
            let data = JSON.stringify(wellInfo)
            const well_selected = criptoJs.AES.encrypt(data, process.env.VUE_APP_KEY_DECRYPT).toString();
            this.$router.push({
                name: 'LayoutStrata',
                params: {
                    well_selected: well_selected,
                },
            });
        },

        changeStatusFavorite() {
            this.showFavorite = !this.showFavorite
            console.log(this.showFavorite)
        },

        async onOpen() {
            if (this.hasNextPage) {
                await this.$nextTick();
                this.observer.observe(this.$refs.load);
            }
        },
        onClose() {
            this.observer.disconnect();
        },
        async getChangeStatus() {
            const objLoader = this.$loading.show();
            this.wellsSelect = [];
            this.page = 1
            this.arrayWell = []
            await this.getWellAll()
            objLoader.hide();
        },

        async getCompany() {
            // const objLoader = this.$loading.show();
            const response = await getAllCompany();
            // objLoader.hide();
            if (response && response.status == 200) {
                this.arrCompany = response.data
            } else {
                response.data && response.data.detail ? this.$toast.info(response.data.detail, toastMessage()) :
                    this.$toast.error("Error when consulting the list of companies", toastMessage());
            }
        },

        async infiniteScroll([{ isIntersecting, target }]) {
            if (isIntersecting) {
                const ul = target.offsetParent;
                const scrollTop = target.offsetParent.scrollTop;
                this.page++;
                await this.getWellAll();
                await this.$nextTick();
                ul.scrollTop = scrollTop;
            }
        },

        async getWellByCompanyId() {
            this.arrayWell = [];
            let objLoader;
            
            try {
                objLoader = this.$loading.show()
                const response = await getWellByIdCompany(this.companyId);

                this.wellsSelect = []
                if (response?.status === 200 && response.data.length > 0) {
                    this.parseIntArray(response.data);
                    this.mapDash(this.arrayWell);
                } else if (response?.status === 200 && response.data.length === 0) {
                    this.$toast.info("No wells were found for the selected company.", toastMessage());
           
                } else {
                    this.$toast.error("Error when querying the list of wells by companies", toastMessage());
                }
            } catch (error) {
                this.$toast.error(error.message, toastMessage());
            } finally {
                if(objLoader) objLoader.hide();
            }
        },

        async getWellAll(search) {
            this.isloading= true
            let url = `${apiWellGetAllParams}&page=${this.page}`;
            let shouldConcatenate = false;

            if (search) {
                url = `${apiWellGetAllParams}${search}&page=${this.page}&limit=${this.perPage}`;
                this.showFavorite = false;
                shouldConcatenate = false;  
            } else {
                shouldConcatenate = true; 
            }
            let objLoader;

            try {
                objLoader = this.$loading.show();
                const response = await getHttp(url);

                if (response?.status === 200) {
                    
                    const previousArray = shouldConcatenate ? this.arrayWell : [];
                    this.parseIntArray(response.data, previousArray);
                    this.mapDash(this.arrayWell);
                } else {
                    throw new Error('An error has occurred while consulting the list of wells');
                }
            } catch (error) {
                this.$toast.error(error.message, toastMessage());
            } finally {
                if (objLoader) objLoader.hide()
                this.isloading= false
            }
        },

        preventTypingDuringLoading(event) {
      if (this.isloading) {
        event.preventDefault(); // Previene la entrada de texto durante la carga
      }},

        async getFavoriteWell() {
            this.changeStatusFavorite()
            const objLoader = this.$loading.show();
            if (this.showFavorite) {
                const response = await getFavoriteWells();
                if (response.status === 200) {
                    console.log(response.data)
                    this.parseIntArray(response.data);
                    this.mapDash(this.arrayWell);
                    //this.arrayWell = response.data;
                } else {
                    console.log(response);

                }
            } else {
                await this.getChangeStatus()
            }
            objLoader.hide();

        },

        async getWellSearch(search, loading) {
            if (search.length) {
                loading(true);
                // const objLoader = this.$loading.show();
                const response = await getAllWellParams(search);
                if (response.status === 200) {
                    loading(false);
                    console.log(response.data)
                    this.parseIntArray(response.data);
                    this.mapDash(this.arrayWell);
                    //this.arrayWell = response.data;
                } else {
                    console.log(response);

                }
            }
            else {
                const response = await getAllWellParams();
                if (response.status === 200) {
                    loading(false);
                    this.arrayWell = response.data;
                    //this.arrayWell = [];
                }
            }
        },
        filtershow() {
            this.bolFilter = true
            this.bolIconFilter = false
        },
        filterhide() {
            this.bolFilter = false
            this.bolIconFilter = true
        },
        convertirCoordenadas(coordenadas) {
            // Validar si el valor ya está en formato decimal
            if (/^\d+,\d+$/.test(coordenadas)) {
                return parseFloat(coordenadas.replace(',', '.')); // Convertir el valor a tipo numérico y retornarlo
            }


            // Validar si el valor está en formato de grados
            var partes = coordenadas.split(/[°' "]+/);
            if (partes.length === 4) {
                var grados = parseFloat(partes[0]);
                var minutos = parseFloat(partes[1]) / 60.0;
                var segundos = parseFloat(partes[2]) / 3600.0;
                var direccion = partes[3].toUpperCase();

                var coordenadaDecimal = grados + minutos + segundos;

                if (direccion === 'S' || direccion === 'W') {
                    coordenadaDecimal = -coordenadaDecimal;
                }

                return coordenadaDecimal;
            }

            // Retornar 0 en caso contrario
            return parseFloat(coordenadas);

        },
        parseIntArray(array, previousArray) {
            console.log("Previous Array", previousArray)
            let tem = [];
            for (let i = 0; i < array.length; i++) {
                let obj = {
                    latitude: this.convertirCoordenadas(array[i].latitude),
                    longitude: this.convertirCoordenadas(array[i].longitude),
                    operating_company: array[i].operating_company,
                    well_name: array[i].well_name,
                    units_of_measurement: array[i].units_of_measurement,
                    id_well: array[i].id,
                    id_pc: array[i].id_pc,
                    color: array[i].favorite ? "gold" : "#000",
                    favorite: array[i].favorite,
                    key: array[i].id_pc + "-" + array[i].id
                };

                tem.push(obj);
            }
            if (previousArray) {
                this.arrayWell = previousArray.concat(tem)
            } else {
                this.arrayWell = tem;
            }

        },

        changeMapPosition(map, isInitial) {
            const ecuCords = { longitude: -91, latitude: 15 };
            const centerCords = { longitude: 0, latitude: 0 };
            if (map) {
                if (window.innerWidth < 901) {
                    if (isInitial) {
                        if (map.projection instanceof am4maps.projections.Orthographic) {
                            map.minZoomLevel = 1;
                            map.homeGeoPoint = centerCords;
                        } else {
                            map.minZoomLevel = 3;
                            map.homeGeoPoint = ecuCords;

                        }
                    }
                    else {
                        if (map.projection instanceof am4maps.projections.Orthographic) {
                            map.minZoomLevel = 1;
                            map.homeGeoPoint = centerCords;
                        } else {
                            map.minZoomLevel = 3;
                            map.homeGeoPoint = ecuCords;

                        }
                    }
                }
                else {
                    if (isInitial) {
                        map.homeGeoPoint = centerCords;
                        map.minZoomLevel = 1;
                    }
                    else {
                        map.homeGeoPoint = centerCords;
                        map.minZoomLevel = 1;
                    }


                }
            }

        },

        loader(action) {
            const objLoader = this.$loading
            if (action == true) {
                objLoader.show()
            } else {
                objLoader.hide()
            }
        },

        mapDash(array) {
            
            var container = am4core.create("chartdiv", am4core.Container);
            container.logo.disabled = true
            container.paddingTop = 15;
            container.layout = "vertical";
            container.width = am4core.percent(100);
            container.height = am4core.percent(100);

            var map = container.createChild(am4maps.MapChart);

            this.changeMapPosition(map, true);
            var button = map.createChild(am4core.SwitchButton);
            button.align = "right";
            button.marginTop = 5;
            button.valign = "bottom";
            button.leftLabel.text = "Map";
            button.rightLabel.text = "Globe";
            button.leftLabel.fill = am4core.color("#fff");
            button.rightLabel.fill = am4core.color("#fff");
            button.events.on("toggled", () => {
                if (button.isActive) {
                    map.projection = new am4maps.projections.Orthographic();
                    map.panBehavior = "rotateLongLat";
                    this.changeMapPosition(map, false);

                } else {
                    map.projection = new am4maps.projections.Miller();
                    map.panBehavior = "move";
                    this.changeMapPosition(map, false);
                }
            });
            try {
                map.geodata = am4geodata_worldLow;
            } catch (e) {
                map.raiseCriticalError(new Error("Map geodata could not be loaded. Please download the latest amcharts geodata and extract its contents into the same directory as your amCharts files."));
            }
            map.projection = new am4maps.projections.Miller();
            map.panBehavior = "rotateLong";
            // prevent dragging
            map.seriesContainer.draggable = false;
            map.seriesContainer.resizable = false;
            // prevent zooming
            //map.minZoomLevel = 1;
            // countries
            var polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
            polygonSeries.useGeodata = true;
            //polygonSeries.mapPolygons.template.fill = am4core.color("#47c78a");
            //polygonSeries.mapPolygons.template.stroke = am4core.color("#47c78a");

            // night series
            var nightSeries = map.series.push(new am4maps.MapPolygonSeries());
            nightSeries.ignoreBounds = true;
            var night = nightSeries.mapPolygons.create();
            night.fill = am4core.color("#000000");
            night.fillOpacity = 0.35;
            night.interactionsEnabled = false;
            night.stroke = am4core.color("#000000");
            night.strokeOpacity = 0;

            //Proyection United State
            var usaSeries = map.series.push(new am4maps.MapPolygonSeries());
            usaSeries.geodata = am4geodata_usaLow;

            var imageSeries = map.series.push(new am4maps.MapImageSeries());
            imageSeries.mapImages.template.createChild(am4core.Circle);

            var usPolygonTemplate = usaSeries.mapPolygons.template;
            usPolygonTemplate.tooltipText = "{name}";
            usPolygonTemplate.fill = am4core.color("#4a90b9");
            usPolygonTemplate.fillOpacity = 1;
            usPolygonTemplate.nonScalingStroke = true;
            let hrs = usPolygonTemplate.states.create("hover");
            hrs.properties.fillOpacity = 0.5;
            usPolygonTemplate.stroke = am4core.color("black");
            usPolygonTemplate.strokeOpacity = 1;

            map.zoomControl = new am4maps.ZoomControl();
            map.zoomControl.align = "left";
            map.zoomControl.valign = "bottom";
            map.zoomControl.marginLeft = 10;

            map.zoomControl.minusButton.background.fill = am4core.color("#C7D0FF");
            map.zoomControl.minusButton.background.fillOpacity = 0.24;
            map.zoomControl.minusButton.background.stroke = null;
            map.zoomControl.plusButton.background.fill = am4core.color("#C7D0FF");
            map.zoomControl.plusButton.background.fillOpacity = 0.24;
            map.zoomControl.plusButton.background.stroke = null;
            map.zoomControl.plusButton.label.fill = am4core.color("#fff");
            map.zoomControl.plusButton.label.fontWeight = 600;
            map.zoomControl.plusButton.label.fontSize = 16;
            map.zoomControl.minusButton.label.fill = am4core.color("#fff");
            map.zoomControl.minusButton.label.fontWeight = 600;
            map.zoomControl.minusButton.label.fontSize = 16;
            let plusButtonHoverState = map.zoomControl.plusButton.background.states.create("hover");
            plusButtonHoverState.properties.fillOpacity = 0.24;
            let minusButtonHoverState = map.zoomControl.minusButton.background.states.create("hover");
            minusButtonHoverState.properties.fillOpacity = 0.24;
            let polygonTemplate = polygonSeries.mapPolygons.template;
            polygonTemplate.tooltipText = "{name}";
            polygonTemplate.fill = am4core.color("#4a90b9");
            polygonTemplate.fillOpacity = 1;
            let hs = polygonTemplate.states.create("hover");
            hs.properties.fillOpacity = 0.5;

            polygonTemplate.stroke = am4core.color("black");
            polygonTemplate.strokeOpacity = 1;

            let placeSeries = map.series.push(new am4maps.MapImageSeries());
            let place = placeSeries.mapImages.template;

            let clusterSeries = map.series.push(new am4maps.MapImageSeries());
            let cluster = clusterSeries.mapImages.template;
            cluster.nonScaling = true;
            cluster.propertyFields.latitude = "latitude";
            cluster.propertyFields.longitude = "longitude";
            
            let circle3 = cluster.createChild(am4core.Circle);
            circle3.radius = 12;
            circle3.fill = am4core.color("#C7D0FF");
            circle3.stroke = am4core.color("#ffffff");
            circle3.strokeWidth = 0;
            circle3.clickable = true;

            var label = cluster.createChild(am4core.Label);
            label.text = "{size}"; // Asignar el valor del punto como texto
            label.fontSize = 12; // Tamaño de fuente
            label.fontWeight = "bold"
            label.fill = am4core.color("#000"); // Color del texto
            label.verticalCenter = "middle";
            label.horizontalCenter = "middle";

            place.nonScaling = true;
            place.propertyFields.latitude = "latitude";
            place.propertyFields.longitude = "longitude";

            let circle = place.createChild(am4core.Circle);
            circle.radius = 2;
            circle.fill = am4core.color("#C7D0FF");
            circle.stroke = am4core.color("#ffffff");
            circle.strokeWidth = 0;
            
            let groupData = []
            map.events.on("zoomlevelchanged",() =>{ 
                groupData = this.groupDataByDistance(array,map.zoomLevel)
                let groupedPoints = groupData.filter(point => point.grouped)
                let ungroupedPoints = groupData.filter(point => !point.grouped)                

                placeSeries.data = ungroupedPoints
                clusterSeries.data = groupedPoints
            })

            //circle.tooltipText = "Company:{operating_company}, Well:{well_name}";
            circle.clickable = true;
            // circle.url = "/id-link/main-menu/25-{id_pc}-{id_well}-57-40";
            this.map = map;
            var circle2 = place.createChild(am4core.Circle);
            circle2.radius = 2;
            circle2.fill = am4core.color("green");

            var circle4 = cluster.createChild(am4core.Circle);
            circle4.radius = 2;
            circle4.fill = am4core.color("#4A90B9");
            circle4.cursorOverStyle = am4core.MouseCursorStyle.pointer
            //circle2.stroke = am4core.color("red");
            var circleTooltipHTML = `
            
                    <div style="text-align: center " onclick="chooseWell('{id_pc}','{id_well}','{units_of_measurement}')">
                        <div style = "direction = row">
                            <b style= "color: #000">Well Information</b>
                        <button style = "background-color: transparent; border:0px"  onclick="event.stopPropagation(); handleAddFavorite('{id_pc}','{id_well}','{favorite}','{key}')">
                            <i id={key} class="mdi mdi-star" style="font-size:20px; color:{color}" ></i>
                            </button>
                        </div>
                        <hr style="color:#000"/>
                        <b style= "color: #000">Company:</b> <span style="color: #000">{operating_company}</span>
                        <br/>
                        <b style= "color: #000">Well:</b> <span style="color: #000">{well_name}</span>
                    </div>
            `
            window.chooseWell = (id_pc,id_well,units_of_measurement) => {
                const wellInfo = {
                    pc_id: id_pc,
                    well_id: id_well,
                    units_of_measurement: units_of_measurement,
                }
                this.onSelectInput(wellInfo)
            }

            window.handleAddFavorite = async (id_pc, id_well, favorite, key) => {

                try {
                    const objLoader = this.$loading.show()
                    let formData = new FormData();
                    formData.append('idWell', id_well);
                    formData.append('idPc', id_pc);
                    if (favorite == true) {
                        await deleteFavoriteWells(formData)
                    } else {
                        await addFavoriteWells(formData)
                    }
                    const index = array.findIndex(objeto => objeto.key === key)
                    if (index !== -1) {
                        const iconElement = document.getElementById(key);
                        if (array[index].favorite == false) {
                            array[index].color = "gold"
                            iconElement.style.color = "gold";

                        } else {
                            array[index].color = "#000"
                            iconElement.style.color = "#000";

                        }
                        array[index].favorite = !array[index].favorite;
                    }
                    objLoader.hide()
                } catch (error) {
                    console.log(error)
                }
            }


            // circle2.url = "/id-link/main-menu/25-{id_pc}-{id_well}-57-40";
            //this.SelectidWell = circle2.uid;
            //circle2.tooltipText = "Company:{operating_company}, Well:{well_name}";
            circle2.tooltip = new am4core.Tooltip();
            circle2.tooltipHTML = circleTooltipHTML
            circle2.tooltip.getFillFromObject = false;
            circle2.tooltip.background.fill = am4core.color("#fff");
            circle2.tooltip.keepTargetHover = true
            circle2.tooltip.label.interactionsEnabled = true;
            const self = this;

            circle4.events.on("hit", function(event){
                const latitude = event.target.dataItem.dataContext.latitude;
                const longitude = event.target.dataItem.dataContext.longitude;

                map.zoomToGeoPoint({ latitude: latitude, longitude: longitude }, 32);
            })

            circle2.events.on("hit", function (event) {

                const pc_id = parseInt(event.target.dataItem.dataContext.id_pc)
                const well_id = parseInt(event.target.dataItem.dataContext.id_well)
                const units_of_measurement = event.target.dataItem.dataContext.units_of_measurement
                //const InfoSelected = event.target.dataItem.dataContext
                const wellInfo = {
                    pc_id: pc_id,
                    well_id: well_id,
                    units_of_measurement: units_of_measurement,
                }

                let data = JSON.stringify(wellInfo)
                const well_selected = criptoJs.AES.encrypt(data, process.env.VUE_APP_KEY_DECRYPT).toString();

                /*const alphanumeric = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
                let randomString = "";

                for (let i = 0; i < 5; i++) {
                const randomChar = alphanumeric.charAt(Math.floor(Math.random() * alphanumeric.length));
                randomString += randomChar;
                }*/


                /*self.$router.push({ name: 'pruebaView',  params: {
                    pc_id: window.btoa(pc_id),
                    ramdom:  window.btoa(randomString),
                    well_id:  window.btoa(well_id)
                } })*/
                self.$router.push({
                    name: 'LayoutStrata', params: {
                        well_selected: well_selected,
                    }
                })


            });
            circle2.events.on("inited", function (event) {
                animateBullet(event.target);
            });

            circle4.events.on("inited", function (event) {
                animateBullet(event.target);
            });
            function animateBullet(circle2) {
                var animation = circle2.animate(
                    [
                        {
                            property: "scale",
                            from: 1,
                            to: 10,
                        },
                        {
                            property: "opacity",
                            from: 1,
                            to: 0,
                        },
                    ],
                    1000,
                    am4core.ease.circleOut
                );
                animation.events.on("animationended", function (event) {
                    animateBullet(event.target.object);
                });
            }
            // images series
            var imagesSeries = map.series.push(new am4maps.MapImageSeries());
            var tooltip = imagesSeries.tooltip;
            tooltip.label.padding(15, 15, 15, 15);
            tooltip.background.cornerRadius = 25;

            // graticule
            var graticuleSeires = map.series.push(new am4maps.GraticuleSeries());
            graticuleSeires.mapLines.template.stroke = am4core.color("#ffffff");
            graticuleSeires.fitExtent = false;

            var lineSeries = map.series.push(new am4maps.MapLineSeries());
            lineSeries.mapLines.template.strokeDasharray = "4,4";
            lineSeries.mapLines.template.stroke = am4core.color("#ffffff");
        },

    },

    mounted() {
        this.observer = new IntersectionObserver(this.infiniteScroll.bind(this));

        if (roleOwner.includes(this.$store.getters.getDataUserRoleAbbreviation)) {
            this.getWellAll()
        } else {
            this.getWellAll();
            this.getCompany()
        }

        // remover el listener cuando se destruye map 
        window.addEventListener('resize', () => {
            this.changeMapPosition(this.map, false);
        });
    },

    onMounted() {
        if (this.map) {
            this.map.dispose();
        }
    },

    beforeUnmount() {
        if (this.map) {
            window.removeEventListener('resize', () => {
                this.changeMapPosition(this.map, false);
            });
        }

    },
};
</script>

<style scoped>
.amcharts-map {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.icon-favorite-selected {
    color: gold;
}

.icon-favorite-deselected {
    color: white;
}

.text-tooltip {
    color: black;
}

.icon-favorite {
    float: right;
    margin-right: 20px;
    /* Otras propiedades de estilo según sea necesario */
}

.tooltip {
    background-color: aliceblue;
}



@media (max-width: 991.98px) {
    .amcharts-map {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
}

.custom-select .vs__dropdown-menu {
  max-height: 200px; /* Establece la altura máxima del dropdown */
  overflow-y: auto; /* Permite el desplazamiento vertical cuando el contenido excede la altura máxima */
}
</style>