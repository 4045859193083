<template>
  <footer class="footer">
    <span class="text-muted text-sm-left derecho"> Copyright© {{ obtenerAnioActual() }}</span>
      <a href="https://www.inreservoir.com/" class="logo">Intelligent Reservoir</a>
  </footer>
</template>

<script>
  export default {
      /**
       * @property component name
       */
      name: "FooterLayout",
      methods:{
        obtenerAnioActual() {
              const fecha = new Date();
              const anio = fecha.getFullYear();
              return anio;
          }
      }
  };
</script>

<style>
  .footer {
    background: #000000;
    padding: 7px 1rem;
    transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    font-weight: bold;
    text-align: center;

  }

  .logo{
    color: #328cc1;
    margin-left: 5px;
    
  }

 .footerIn{
  text-align: center;
  font-weight: bold;
 }
  
</style>