const pathWell = '/assign-well-to-company'

const routes = [{
    path: pathWell,
    name: 'AssignWellToCompany',
    component: () =>
        import ( /* webpackChunkName: "ListWellsByCompany" */ '../ui/ListWellsByCompany.vue'),
    title: 'AssignWellToCompany',
    meta: {
        requireAuth: true,
        roles: [],
        permissions: [
            '_viewer_associate_well_company'
        ]
    }
}, ]

export default routes