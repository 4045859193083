/** 
 * @description route MenuOption
 */
import { prefix } from "@/config/prefix"
import strata from "../../strataId/router"
const pre = prefix
const pathMainMenu = '/main-menu/:well_selected'


const routes = [{
    path: pre + pathMainMenu,
    name: 'LayoutStrata',
    component: () =>
        import ( /* webpackChunkName: "LayoutStrata" */ '../ui/LayoutStrata.vue'),
    title: 'LayoutStrata',
    meta: {
        requireAuth: true,
        roles: [],
        permissions: []
    },
    children: [

        {
            path: pre + pathMainMenu,
            name: 'MenuMain',
            component: () =>
                import ( /* webpackChunkName: "MenuMain" */ '../ui/MenuMain.vue'),
            title: 'MenuMain',
            meta: { requireAuth: true },
        },
        ...strata

    ]

}, ]

export default routes