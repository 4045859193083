<template>
    <div>
        <li class="nav-item profile">
            <div class="profile-desc">
                <div class="profile-pic">
                    <div class="count-indicator">
                        <img class="img-xs rounded-circle" :src="srtSetIMG" alt="" />
                        <!-- <span class="count bg-success"></span> -->
                    </div>
                    <div class="profile-name">
                        <h6 class="mb-0 font-weight-normal">
                            {{ $store.getters.getDataUser?.first_name }}
                        </h6>
                        <span>{{ $store.getters.getDataUser.role ? $store.getters.getDataUser.role.name : ''}}</span>
                    </div>
                </div>
                <router-link  to="" id="profile-dropdown" data-toggle="dropdown" aria-expanded="true"
                @click="toggleMenu"
                ><i class="mdi mdi-dots-vertical"></i></router-link>
                <div :class="[isActive ? 'show' : '']" class="dropdown-menu dropdown-menu-right sidebar-dropdown preview-list config-profile st-style-dropdown" aria-labelledby="profile-dropdown">
                    <router-link  :to="{name:'ResetPassword'}" class="dropdown-item preview-item" exact-active-class="exact-active"
                    @click="isActive = !isActive">
                        <div class="preview-thumbnail">
                            <div class="preview-icon bg-dark rounded-circle">
                                <i class="mdi mdi-onepassword text-info"></i>
                            </div>
                        </div>
                        <div class="preview-item-content" >
                            
                                <p class="preview-subject ellipsis mb-1 text-small">
                                    Change Password
                                </p>
                        </div>
                    </router-link>
                    <div class="dropdown-divider"></div>
                    <router-link  :to="{name:'ProfileUser'}" class="dropdown-item preview-item" exact-active-class="exact-active"
                    @click="isActive = !isActive">
                        <div class="preview-thumbnail">
                            <div class="preview-icon bg-dark rounded-circle">
                                <i class="mdi mdi-account-key text-info"></i>
                            </div>
                        </div>
                        <div class="preview-item-content" >
                            
                                <p class="preview-subject ellipsis mb-1 text-small">
                                    Profile Settings
                                </p>
                        </div>
                    </router-link>
    
                </div>
            </div>
        </li>
    </div>
</template>
<script>
    // import { setSwallWarning, toastMessage } from "@/utils/baseFunctions";
    import { getImgUser } from "@/utils/imgFuntionsj";
    export default {
        name: "DropSidebar",
        data() {
            return {
                srtSetIMG: "",
                isActive:false,
            };
        },
        methods: {
            async loadImgUser() {
                this.srtSetIMG = await getImgUser();
            },

            closeMenu() {
                if(this.isActive && !event.target.closest(".dropdown-menu")){
                    this.isActive = false;
                }
            },

            toggleMenu(event) {
                event.stopPropagation(); // Evita que el evento de clic se propague al documento
                this.isActive = !this.isActive;
            },

            /**
             * @description removes the user's authentication token and logs the user out.
             */
            // logout: function () {
            //     this.$swal(setSwallWarning(`<h4>¿Confirm Log Out?</h4>`, "")).then((result) => {
            //         if (result.isConfirmed) {
            //             //localStorage.removeItem("keyToken");
            //             localStorage.clear();
            //             setTimeout(() => this.$router.push({ name: "LoginView" }), 1000);
            //         } else {
            //             this.$toast.error("An error occurred when querying user information", toastMessage());
            //         }
            //     });
            // },
        },
        mounted: function () {
            this.loadImgUser();

        },
        created (){
            document.addEventListener("click", this.closeMenu);
         },

        beforeDestroy() {
            document.removeEventListener("click", this.closeMenu);
        },
    };
</script>
<style scoped>

@media screen and (max-width: 991px) {
    .config-profile {
    transform: translate3d(52px, 40px, 0px);
    }
}
</style>
