function getErrors(errors) {
    var result = [];
    if (typeof errors !== 'object') return [errors];
    (function flatErrors(errors) {
        for (let value of Object.values(errors)) {
            if (typeof value === 'object') value = flatErrors(value)
            if (value) result.push(value)
        }
    })(errors);
    return result;
}

export { getErrors };