/** 
 * @description Route Module Strata-Id
 */
import { prefix } from "@/config/prefix"
const pre = prefix
    /** 
     * @description route ConfigurationVariables
     */
const routes = [{
    /** 
     * @description route ConfigurationVariables
     */
    path: pre + '/configuration-variables',
    name: 'ConfigurationVariables',
    component: () =>
        import ( /* webpackChunkName: "ConfigurationVariables" */ '../ui/ListVariables.vue'),
    title: 'ConfigurationVariables',
    meta: { requireAuth: true },
}, ]


export default routes