//POST
export const apiWellGetAll = "/api/strata/well"

export const apiWellGetAllParams = '/api/strata/well?search='

export const apiWellGetCompanyId = "/api/strata/well/by-companies" //{companyId}

export const apiWellAssigCompany = "api/well-assignment/company"

export const apiWellAssigUser = "api/well-assignment/user"

//favoritewells
export const apiGetFavoriteWells = "api/favorite-wells/getFavoriteWells"
export const deleteFavoriteWell = "api/favorite-wells/deleteFavoriteWell"
export const addFavoriteWell = "api/favorite-wells/addFavoriteWell"
