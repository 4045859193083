import { prefix } from "@/config/prefix";

const pre = prefix;
const pathRT = "/operations-real-time/:well_selected";

/**
 * @description route OperationsViews
 */
const routes = [

    {
        path: pre + pathRT,
        name: "OperationsViewsRT",
        component: () =>
            import ( /* webpackChunkName: "OperationsRealTime" */ "../ui/OperationsRealTime.vue"),
        meta: { requireAuth: true },
    },
];

export default routes;