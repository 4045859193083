/** 
 * @description route Forgot-Password
 */
const pathForgotPass = '/forgot-password'
const routes = [{
    path: pathForgotPass,
    name: 'ForgotPassword',
    component: () =>
        import ( /* webpackChunkName: "ForgotPassword" */ '../ui/ForgotPassword.vue'),
    title: 'ForgotPassword',
}, ]

export default routes