<template>
  <div style="height: 100%">
    <div class="row" style="height: 100%">
      <div :class="columnClass" @click="toggleSecondDiv" style="height: 20%">
        <div class="size-map">
          <div class="button-container" :class="{ active: isActive }">
            <button
              @click="toggleButton"
              :class="{ active: isActive }"
              class="custom-button"
            >
              <span class="toggle-label">{{ isActive ? "" : "" }}</span>
              <span class="toggle-slider"></span>
            </button>
          </div>
          <MapAdmin />
        </div>
      </div>
      <div
        v-if="showSecondDiv"
        class="second-div data-well "
        style="height: 78%"
      >
        <div class="scrollable-content">
          <div class="scroll-content">
            <div class="data-well-content">
              <div class="row justify-content-center align-item-center">
                <div class="col-md-12 col-12">
                  <highcharts :options="chartOptions"></highcharts>
                </div>
                <div
                  v-for="(data, index) in arrayCounterWells"
                  :key="data.i"
                  :class="index === 0 ? 'col-12' : 'col-6'"
                  class="text-center"
                >
                  <div style="background: #0b3c5d; border-radius: 8px">
                    <label style="font-size: 0.7em">{{
                      data.description
                    }}</label>
                    <p style="font-size: 0.6em">{{ data.count }}</p>
                  </div>
                </div>
                <tableInfo></tableInfo>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapAdmin from "@/modules/maps/ui/MapView.vue";
import { Chart } from "highcharts-vue";
import * as Highcharts from "highcharts/highcharts";
import "highcharts/themes/dark-unica.js";
//import Highcharts from "highcharts";
import accessibility from "highcharts/modules/accessibility";
import { getHttp } from "@/utils/http";
import { apiCountWellStatus, apiCountWellGroup } from "../UriApi";
import { toastMessage } from "@/utils/baseFunctions";
accessibility(Highcharts);

import useCompany from "@/modules/administration/company/composable/useCompany";
const { getAllCompany } = useCompany();

import tableInfo from "./TableInformation";

export default {
  /**
   * @property component name
   */
  name: "DashboardView",
  /**
   * @property component initialization
   */
  components: {
    MapAdmin,
    highcharts: Chart,
    tableInfo,
  },

  data() {
    return {
      showSecondDiv: false,
      isActive: false,
      columnClass: "col-md-12 col-12 setHeight",
      arrayCounterWells: [],
      arrayCountWellsCompany: [],
      totalCompany: "",
      CountWellUser: "",
      chartOptions: {
        colors: ["#4a90b9", "#1f8fb9", "#0d6efd", "#8458b5d1", "#204c38"],
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          backgroundColor: "black",

          height: 250,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.y:,.0f}wells</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
            size: "100%", // tamaño relativo del gráfico de tipo pie
          },
        },
        legend: {
          layout: "horizontal", // Ubicar la leyenda en posición horizontal
          align: "center", // Centrar la leyenda horizontalmente
          verticalAlign: "bottom",
          itemWidth: 85,
          useHTML: true,
          itemStyle: {
            fontSize: "8px", // tamaño de la fuente de las leyendas del gráfico de tipo pie
            color: "white  ",
          },
        },
        series: [
          {
            name: "Brands",
            colorByPoint: true,
            data: [],
          },
        ],
      },
    };
  },
  methods: {

       
    toggleButton() {
      this.isActive = !this.isActive;
      this.showSecondDiv = this.isActive;
      this.columnClass = this.isActive
        ? "container-map setHeight"
        : "col-md-12 col-12 setHeight";
    },
    toggleSecondDiv(event) {
      event.stopPropagation();
    },
    async getCountWellStatus() {
      // const objLoader = this.$loading.show();
      const response = await getHttp(apiCountWellStatus);
      // objLoader.hide();
      if (response?.status == 200) {
        this.arrayCounterWells = response.data;
      } else {
        this.$toast.error(
          "An error has occurred while consulting the count of wells",
          toastMessage()
        );
      }
    },
    stateWellCount(array) {
      //let arrayStateWell = array.sort((a, b) => b - a);
      //let arraytem =arrayStateWell.slice(0,5)
      this.arrayCountWellsState = array.map((item) => ({
        name: item.state,
        y: item.count,
      }));
      this.chartOptions.series[0].data = this.arrayCountWellsState;
    },
    async loadInitialData() {
      const objLoader = this.$loading.show();
      await Promise.all([
        this.getCountWellStatus(),
        this.getCountWellGroup(),
        this.getCompany(),
      ]);
      objLoader.hide();
    },
    async getCountWellGroup() {
      // const objLoader = this.$loading.show();
      const response = await getHttp(apiCountWellGroup);
      //objLoader.hide();
      if (response?.status == 200) {
        this.stateWellCount(response.data.count_by_state);
      } else {
        this.$toast.error(
          "An error has occurred while consulting the count of wells",
          toastMessage()
        );
      }
    },
    async getCompany() {
      try {
        //const objLoader = this.$loading.show();
        const response = await getAllCompany();
        //objLoader.hide();


            if ([200, 201, 204].includes(response.status)) {
              this.totalCompany = response.data.length;
              
            } else {
              const detail = response.data?.detail;
              detail ? this.$toast.info(detail, toastMessage()) : this.$toast.error("Error when consulting the list of companies", toastMessage());
            }

      } catch (error) {
        this.$toast.error(
          "An error has occurred when consulting the list of companies",
          toastMessage()
        );
      }
    },
  },
  mounted() {
    this.loadInitialData();
  },
  created() {},
};
</script>

<style scoped>

@media (max-width: 991.98px) {
  .container-map{
    width: 100% !important;
  }
  .second-div{
    width: 100% !important;
}

}

.container-map{
  width: 75%;
}
.second-div{
  width: 25%;
}
.data-well {
  margin-top: 30px;
}

.custom-button {
  position: relative;
  background-color: #985E6D;
  border-radius: 20px;
  width: 50px; /* Tamaño constante */
  height: 23px; /* Tamaño constante */
  cursor: pointer;
  outline: none;
  border: 2px solid white;

}

.active .custom-button{
  background-color: #002B4F;
  border: 2px solid #0EA070;

}

.button-container {
  display: flex;
  justify-content: flex-end; /* Alinea el botón a la derecha */
  margin-left: 10px; /* Margen entre el botón y el componente MapAdmin */
}

.toggle-label {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  font-size: 12px;
  color: gray;
}

.toggle-slider {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
}

.active .toggle-slider {
  transform: translateX(28px);
}

.data-well-content {
  height: 100%;
}

.scroll-content {
  box-sizing: border-box;
  color: white;
  font-size: 18px;
}

.scrollable-content {
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  color: white;
  font-size: 18px;
}


</style>
