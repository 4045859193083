/**
 * @description route Company
 */
const pathCompany = '/company'

const routes = [{
    path: pathCompany,
    name: 'CompanyView',
    component: () =>
        import ( /* webpackChunkName: "CompanyView" */ '../ui/Company.vue'),
    title: 'CompanyView',
    meta: {
        requireAuth: true,
        roles: [],
        permissions: [ '_viewer_company' ]
    }
}, ]

export default routes