/** 
 * @description route Login
 */
const pathLogin = '/login'
const routes = [{
    path: pathLogin,
    name: 'LoginView',
    component: () =>
        import ( /* webpackChunkName: "LoginView" */ '../ui/Login.vue'),
    title: 'LoginView',
}, ]

export default routes