<template>
  <nav
    class="navbar p-0 fixed-top d-flex flex-row header__information"
    id="navigation"
    style="background: #080f19 !important"
  >
    <div
      class="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center"
    >
      <!-- <a class="navbar-brand brand-logo-mini" href="index.html">
                <img class="logo-id" src="@/assets/pictures/id.png" alt="logo" />
            </a> -->
      <router-link :to="{ name: 'AppLayoutPublic' }" class="brand-link">
        <span
          class="navbar-brand brand-logo-mini d-flex justify-content-center"
        >
          <img src="@/assets/pictures/id.png" alt="IdLink-D" />
        </span>
      </router-link>
    </div>

    <div class="navbar-menu-wrapper d-flex align-items-stretch">
      <button
        class="navbar-toggler navbar-toggler align-self-center d-lg-block"
        type="button"
        @click="toggleSidebar()"
      >
        <span class="mdi mdi-menu"> </span>
      </button>
      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item nav-settings d-none d-lg-block">
          <dropdown></dropdown>
        </li>

        <li>
          <notificaciones/>
        </li>
        <!-- <li class="nav-item dropdown">
                    <a class="nav-link count-indicator dropdown-toggle" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                        <i class="mdi mdi-email-outline text-muted"></i>
                    </a>
                </li> -->
        <!-- <li class="nav-item dropdown border-left">
                    <a class="nav-link count-indicator dropdown-toggle" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                        <i class="mdi mdi-web text-muted"></i>
                    </a>
                </li> -->
        <li class="nav-item dropdown">
          <!-- <a class="nav-link count-indicator dropdown-toggle" id="configurationdropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                        <i class="mdi mdi-account-key text-muted"></i>
                    </a> -->
          <div
            class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
            aria-labelledby="configurationdropdown"
          >
            <h6 class="p-3 mb-0">Configurations</h6>
            <template
              v-if="
                $store.getters.getDataUser.role &&
                $store.getters.getDataUser.role.id != 1
              "
            >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item preview-item">
                <div class="preview-thumbnail">
                  <div class="preview-icon bg-dark rounded-circle">
                    <i class="mdi mdi-account text-danger"></i>
                  </div>
                </div>
                <div class="preview-item-content">
                  <p class="preview-subject mb-1">My Account</p>
                </div>
              </a>
            </template>
            <div class="dropdown-divider"></div>
            <router-link to="/calendar" class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-dark rounded-circle">
                  <i class="mdi mdi-calendar text-success"></i>
                </div>
              </div>
              <div class="preview-item-content">
                <p class="preview-subject mb-1">Calendar</p>
              </div>
            </router-link>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link count-indicator dropdown-toggle"
            id="messageDropdown"
            href="#"
            data-toggle="dropdown"
            aria-expanded="false"
            @click="logout"
          >
            <i class="mdi mdi-power text-muted"></i>
          </a>
        </li>
      </ul>
      <button
        class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
        type="button"
        data-toggle="offcanvas"
        @click="toggleMobileSidebar()"
      >
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </nav>
</template>

<script>
/**
 * @property import Libraries and components
 * */
import dropdown from "./Dropdown";
import notificaciones from "../../modules/notifications/ui/NotificationList.vue"
import { setSwallWarning } from "@/utils/baseFunctions";
export default {
  /**
   * @property component name
   */
  name: "NavbarView",
  components: {
    dropdown,
    notificaciones
  },
  data() {
    return {
      objParam: {},

   };
  },
  methods: {
    toggleSidebar: () => {
      document.querySelector("body").classList.toggle("sidebar-icon-only");
      document.querySelector(".dropdown-menu").classList.remove("show");
    },
    toggleMobileSidebar: () => {
      document.querySelector("#sidebar").classList.toggle("active");
    },
    logout: function () {
      this.$swal(setSwallWarning(`<h4>¿Confirm Log Out?</h4>`, "")).then(
        (result) => {
          if (result.isConfirmed) {
            //localStorage.removeItem("keyToken");
            this.$store.dispatch("storeAuthenticateDataUser", {});
            this.$store.dispatch("readToken", null);
            this.$store.dispatch("refreshToken", null);
            this.$store.dispatch("storeObjPermissions", {});
            localStorage.clear();
            setTimeout(() => this.$router.push({ name: "LoginView" }), 1000);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.navbar-menu-wrapper {
  position: static;
}

</style>
