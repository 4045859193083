import jwt_decode from "jwt-decode";
import dayjs from 'dayjs'
import store from '../store'
import router from '../router'


const URL_BASE = process.env.VUE_APP_WEBSOCKET || 'ws://127.0.0.1:8000/ws/'

/**
 * 
 * @param {*} object 
 * @returns 
 */
const buildQueryParamByObject = (object) => {
    const params = new URLSearchParams(object);
    return params.toString();
};
/**
 * 
 * @param {*} uri 
 * @param {*} params 
 * @returns 
 */
const buildUrl = (uri, params) => {
    params.token = getToken();
    const queryParams = buildQueryParamByObject(params)
    return `${URL_BASE}${uri}?${queryParams}`;
};

/**
 * 
 * @returns 
 */
const getToken = () => {
    const user = jwt_decode(store.getters.getAccessToken);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    const authorizationToken = isExpired ? store.getters.getRefreshToken : store.getters.getAccessToken;
    return authorizationToken
};

/**
 * 
 * @param {*} event 
 */
const handleConnect = (event) => {
    console.log("Successfully connected to the echo websocket server...")
    console.log(event)
};

/**
 * 
 * @param {*} event 
 * @returns 
 */
const handleValidateAuthorization = (event) => {
    const code = event.data.code !== undefined ? event.data.code : 0
    if (code !== 401) return

    store.dispatch('storeAuthenticateDataUser', {})
    store.dispatch('readToken', null)
    store.dispatch('refreshToken', null)
    store.dispatch('storeObjPermissions', {})
    localStorage.clear();
    router.push({ name: "LoginView" })
};

/**
 * Class WebSocketClient
 */
class WebSocketClient extends WebSocket {

    /**
     * sent message to the server
     * @param {object} message 
     */
    async emit(message) {
        if (message == null) {
            throw new Error("message is required");
        }
        if (message instanceof Object) {
            message = JSON.stringify(message)
        }
        await this.send(message)
    }
}


/**
 *  export module 
 * @param {*} uri 
 * @param {*} params 
 * @returns 
 */
export const webSocketClientAuth = async(uri, params = {}) => {
    console.log("Starting connection to WebSocket Server")
    let socketConnecttion = await new WebSocketClient(buildUrl(uri, params))
    socketConnecttion.addEventListener("open", handleConnect)
    socketConnecttion.addEventListener("message", handleValidateAuthorization)
    return socketConnecttion
};