<template>
    <div class="container-scroller" style="background: black;">
        <Sidebar />
        <div class="page-body-wrapper">
            <Navbar />
            <div class="main-panel">
                <div class="content-wrapper ">
                    <template v-if="currentRouteName == srtTem">
                        <Dashboard />
                    </template>
                    <template v-else>
                        <router-view />
                    </template>
                </div>
            
            </div>
            <FooterLayout />
        </div>
    </div>
    
</template>

<script>
/**
 * @property import libraries
 */
import Navbar from "./components/navbar/Navbar.vue";
import Sidebar from "./components/sidebar/Sidebar.vue";
import Dashboard from "./modules/dashboard/ui/Dashboard.vue";
import useUser from './modules/administration/user/composable/useUser';
import FooterLayout from "./components/footer/FooterLayout.vue";
const { getUserById } = useUser()
import { toastMessage } from "@/utils/baseFunctions";


export default {
    /**
     * @property component name
     */
    name: "AppLayoutView",
    /**
     * @property component initialization
     */
    components: {
        Navbar,
        Sidebar,
        Dashboard,
        FooterLayout,
    },
    /**
     * @property computed component properties
     */
    computed: {
        /**
         * @description gets the current route
         */
        currentRouteName() {
            return this.$route.name;
        },
    },
    /**
     * @property data, initialization of component variables
     */

    data() {
        return {
            srtTem: "AppLayoutView",
        };
    },

    methods: {

        toogleComponent() {
            this.showMenuStrata = !this.showMenuStrata
        },

        async getUserById() {
            if (!this.$store.getters.getIdUser) return

            
            const response = await getUserById( this.$store.getters.getIdUser );
           
            if (response.status === 200) {
                this.$store.dispatch('storeAuthenticateDataUser', response.data);
            } else {
                response.datail ? this.$toast.info(response.detail, toastMessage())
                    : this.$toast.error("Error when querying user information", toastMessage());
            }
        },
    },
    created(){

        this.getUserById()

        if (!this.$store.getters.getDataUser.email ) {
            this.$store.dispatch('storeAuthenticateDataUser', {})
            this.$store.dispatch('readToken', null)
            this.$store.dispatch('refreshToken', null)
            this.$store.dispatch('storeObjPermissions', {})
            localStorage.clear();
            this.$router.push({ name: "LoginView" })
        }
    }
};
</script>




