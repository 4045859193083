/** 
 * @description route Solutions
 */
const pathSolutions = '/solutions'
const routes = [{
    path: pathSolutions,
    name: 'SolutionsView',
    component: () =>
        import ( /* webpackChunkName: "SolutionsView" */ '../ui/Solutions.vue'),
    title: 'SolutionsView',
}, ]

export default routes