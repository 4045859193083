<template>
    <div>
      <div class="col-12">
        <div class="position-relative title-stad">
          <span style="color:rgb(132 190 223)">Total Company</span>
          <span style="float:right; color:rgb(132 190 223)">{{ totalCompany }}</span>
        </div>
      </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Quantity of Well</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in arrayCountCompany" :key="data.i">
              <td>{{data.company_name}}</td>
              <td class="text-center">{{data.well_count}}</td>
            </tr>
          </tbody>
        </table>
        <div class="col-12">
          <div class="position-relative title-stad">
            <span style="color:rgb(132 190 223)">Total Users</span>
            <span style="float:right; color:rgb(132 190 223)">{{ totalUser }}</span>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Quantity of Well</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in arrayCountUser" :key="data.i">
              <td>{{data.user_name}}</td>
              <td class="text-center">{{data.well_count}}</td>
            </tr>
          </tbody>
        </table>
      </div>
</template>
<script>
import { toastMessage } from "@/utils/baseFunctions";
import { getHttp } from "@/utils/http";
import useCompany from "@/modules/administration/company/composable/useCompany";
const { getAllCompany } = useCompany();
import { apiUsers } from "@/modules/administration/user/uriApi.js";
import {apiTopUsers,  apiTopCompanies} from "../UriApi"


export default {
  name: 'TableInformation',
  data() {
    return{
      totalCompany: '',
      totalUser:'',
      arrayCountCompany: [
        
      ],
      arrayCountUser: [
        
      ]
    }
  },
  methods: {
    async getCountCompany() {
      try {
       // const objLoader = this.$loading.show();
        const response = await getAllCompany();
        //objLoader.hide();

        if ([200, 201, 204].includes(response.status)) {
          this.totalCompany = response.data.length;
          
        } else {
          const detail = response.data?.detail;
          detail ? this.$toast.info(detail, toastMessage()) : this.$toast.error("Error when consulting the list of companies", toastMessage());
        }
      } catch (error) {
        this.$toast.error("An error has occurred when consulting the list of companies", toastMessage());
      }
    },
    async getCountUser() {
           // const objLoader = this.$loading.show();
            const response = await getHttp(apiUsers);
            //objLoader.hide();
      if (response.status == 200) {
              this.totalUser=response.data.length
            } else {
                !response.message ? this.$toast.error(response.message, toastMessage()) : this.$toast.error(`Error when obtaining the list of users`, toastMessage());
            }
    },

    async getTopUser() {
            //const objLoader = this.$loading.show();
            const response = await getHttp(apiTopUsers);
            //objLoader.hide();
      if (response.status == 200) {
              this.arrayCountUser=response.data
            } else {
                !response.message ? this.$toast.error(response.message, toastMessage()) : this.$toast.error(`Error when obtaining the top of users`, toastMessage());
            }
    },
    async getTopCompany() {
            //const objLoader = this.$loading.show();
            const response = await getHttp(apiTopCompanies);
            //objLoader.hide();
      if (response.status == 200) {
              this.arrayCountCompany=response.data
            } else {
                !response.message ? this.$toast.error(response.message, toastMessage()) : this.$toast.error(`Error when obtaining the top of companies`, toastMessage());
            }
    },
  },
  mounted() {
    this.getCountCompany();
    this.getCountUser();
    this.getTopCompany();
    this.getTopUser();
  }
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

th, td {
  padding: 3px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

thead th {
  background-color: #0b3c5d;
  color: white;
}
.title-stad{
  padding: 5px 0;
}
</style>