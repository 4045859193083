<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style src="./assets/css/style.css"></style>

<script>
export default {
  /**
     * @property Component name
  */
  name: "App"
};

</script>
<style>


</style>