/** 
 * @description route About
 */
const pathAbout = '/about'
const routes = [{
    path: pathAbout,
    name: 'AboutView',
    component: () =>
        import ( /* webpackChunkName: "AboutView" */ '../ui/About.vue'),
    title: 'AboutView',
}, ]

export default routes