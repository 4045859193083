export default {
    /**
     * @description gets the prefix value of the state
     */
    getPrefix: (state) => state.prefix,

    /**
     * @description gets the srtDataImg value of the state
     */
    getDataImg: (state) => state.srtDataImg,
    /**
     * @description gets the id value of the state
     */
    //getUserID: (state) => state.idUser,
    /**
     * @description gets the authToken value of the state
     */
    getAccessToken: (state) => state.authToken,

    getRefreshToken: (state) => state.refresh_token,
    /**
     * @description
     */
    getDataUser: (state) => state.objUrser,

    getDataUserRoleAbbreviation: (state) => state.objUrser?.role?.abbreviation,

    getIdUser: (state) => state.objUrser?.id,

    getIdCompany: (state) => state.objUrser?.company?.id,

    getIsSuperuser: (state) => state.objUrser.is_superuser,

    getObjPermissions: (state) => state.objPermissions,

    getLimitsMS: (state) => state.limitsMs,

    /* getIdCompania: state => state.idCompania, */
};
