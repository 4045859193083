import { createApp } from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const app = createApp({});
app.use(VueToast);
/**
 * @description Shows a toast notification based on the given response object.
 * If the response is an object, it will check for the presence of the message or data property to display an info toast.
 * If the response is a string, it will display an info toast with the string as message.
 * If the response is not a string or an object, it will display an error toast with a default message.
 *
 * @param { Object|String } response - The response object or string to show as toast message.
 * @param { String } [msjPersonalize] - Optional parameter to customize the error message.
 * @param { String } [type] - Optional parameter to set the type of toast notification ('success' or 'error').
 * @returns { void }
 * @throws { TypeError } If response is not an object or a string.
 */
export const showToast = (response, msjPersonalize, type) => {
    const toastMsg = toastMessage();
    if (typeof response === "object") return showObjectResponseToast(response, msjPersonalize, type || 'info');
    if (typeof response === "string") return showObjectResponseToast(response, msjPersonalize, type || 'info');
    return app.config.globalProperties.$toast.error(msjPersonalize || "Unknown error", toastMsg);
};

/**
 *  @description Shows a toast notification based on the given response object.
 * It will check for the presence of the message or data property to display an info toast.
 * If the response object does not have a message or data property, it will display an error toast with a default message.
 *
 * @param { Object } response - The response object to show as toast message.
 * @param { String } [msjPersonalize] - Optional parameter to customize the error message.
 * @param { String } [type] - Optional parameter to set the type of toast notification ('success' or 'info').
 */
export const showObjectResponseToast = (response, msjPersonalize, type) => {
    const toastMsg = toastMessage();

    const resolveToastType = (msg, type) => {
        if (type === "success") {
            app.config.globalProperties.$toast.success(msg, toastMsg);
        } else if (type === "error") {
            app.config.globalProperties.$toast.error(msg, toastMsg);
        } else {
            app.config.globalProperties.$toast.info(msg, toastMsg);
        }
    };

    if (typeof response === "object") {
        if (response.data && typeof response.data === "object") {
            if (response.data.message && typeof response.data.message === "string") return resolveToastType(response.data.message, type);
            if (response.data.title && typeof response.data.title === "string") return resolveToastType(response.data.title, type);
            if (response.data.errors && typeof response.data.errors === "object") return resolveToastType(JSON.stringify(response.data.errors), 'error');
        }
        if (response.message && typeof response.message === "string") return resolveToastType(response.message, type);
        if (response.data && typeof response.data === "string") return resolveToastType(response.data, type);
        return app.config.globalProperties.$toast.error(msjPersonalize || "Unknown error", toastMsg);
    }
    if (typeof response === "string") return resolveToastType(response, type);
    return app.config.globalProperties.$toast.error(msjPersonalize || "Unknown error", toastMsg);
};


/**
 * @description initialization of warning object for user acknowledgement messages to be displayed in the interface
 * @param {String} html render the notification question
 * @param {String} text renders the notification message
 * @return {Object}
 */
export function setSwallWarning(title, text = null, cicon = "warning") {
    const warning = {
        title: title,
        text: text,
        icon: cicon,
        showCancelButton: true,
        confirmButtonText: "Yes, Confirm!",
        cancelButtonText: "Cancel",
        background: "#191c24",
    };
    return warning;
}

/**
 * @description initialization of toastMessage object for notification messages, success, info, warning, error
 * @return {Object}
 */
export function toastMessage() {
    const toastMessage = {
        position: "top-right",
        duration: 3000,
        dismissible: true,
        pauseOnHover: true,
    };
    return toastMessage;
}

export function Swalfire(title) {
    const swalfire = {
        icon: "success",
        title: title,
        showConfirmButton: false,
        timer: 1500,
        background: "#191c24",
    };
    return swalfire;
}

/**
 * @description verifies that the string sent is decimal/float/double
 * @param { String } n string to validate
 * @returns { Boolean } true or false
 */
export function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}
/**
 * @description validates the valid input format (23/2, 1/2 1, 1/8 1/8) for measurement fields
 * @param { String } prmFraction string to validate
 * @returns { Boolean } true or false
 */
export function validateString(prmFraction) {
    let valoresOutSpace = prmFraction.split(" ")
    let valoresOutBar = prmFraction.split("/");

    if (valoresOutBar.length > 2) return false;

    if (valoresOutSpace.length == 1) {
        let valoresNum = valoresOutSpace[0].split("/");
        if (valoresNum.length == 2) {
            if (Number(valoresNum[0]) != null) {
                if (Number(valoresNum[1]) != null) {
                    if (Number(valoresNum[0]) && !isFloat(Number(valoresNum[1]))) {
                        return (Number(valoresNum[0]) / Number(valoresNum[1]))
                    } else return false
                } else return false;
            } else return false;
        }

        if (valoresNum.length == 1) {
            if (Number(valoresNum[0]) != null && Number(valoresNum[0])) return Number(valoresNum[0]);
            else return false;
        }
    }

    if (valoresOutSpace.length == 2) {
        let valoresOutDiv = valoresOutSpace[1].split("/");
        if (valoresOutDiv.length == 2) {
            if (Number(valoresOutDiv[0]) != null) {
                if (Number(valoresOutDiv[1]) != null) {
                    if (Number(valoresOutSpace[0]) && Number(valoresOutDiv[0]) && Number(valoresOutDiv[1])) {
                        const a = Number(valoresOutSpace[0])
                        const resp = (a * Number(valoresOutDiv[1]) + Number(valoresOutDiv[0]))
                        return resp / Number(valoresOutDiv[1])
                    } else return false;
                } else return false;
            } else return false;
        }
    }
    return false;
}
/**
 *   @description sorts an array of objects in an ascending or descending order
 *   @param { String } property If the property arrives with "property" it sorts in ascending order,
                     If the property arrives with "-property" it sorts descending,
 *   @returns { Array } array sorted in ascending or descending order
 */
export function dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function(a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
}
/**
 * Creates a dynamic sorting function that sorts objects based on multiple properties.
 *
 * @param { ...string } properties - The properties to sort by.
 * @returns { Function } The dynamic sorting function.
 */
export function dynamicSortMultiple(...properties) {
    /**
     * The dynamic sorting function for multiple properties.
     *
     * @param { Object } obj1 - The first object to compare.
     * @param { Object } obj2 - The second object to compare.
     * @returns { number } The comparison result.
     */
    return function(obj1, obj2) {
        for (const property of properties) {
            const result = dynamicSort(property)(obj1, obj2);
            if (result !== 0) {
                return result;
            }
        }
        return 0;
    };
}

/**
 * @description Returns the formatted value according to the number of decimal places received.
 * @param { String } value
 * @param { Integer } numDecimals
 * @returns { String } str
 */
export function formatDecimals(value, numDecimals) {
    return numDecimals == 0 ? value : Number.parseFloat(value).toFixed(numDecimals).toString();
}