import { createStore } from "vuex";
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

import MenuOptions from '../modules/menu/store/index'
import formationEvaluation from '../modules/strataId/formationEvaluation/store/index'

/**
 * @description Vuex-Store instance
 */
export default new createStore({
    strict: true, //disable for prod,

    plugins: [
        /**
         * @description Creates a new instance of the plugin with the given options
         * @param {String} key The key to store the persisted state under. Defaults to vuex.
         * @param {String} value The value to store the persisted state under. Defaults to vuex.
         */
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],

    /**
     * @property vuex store modules
     */
    modules: {
        MenuOptions,
        formationEvaluation,
    },
    /**
     * @property state, initialization of store variables
     */
    state: {
        prefix: '/id-link',

        //idUser: null,
        authToken: null,
        refresh_token: null,

        srtDataImg: 'data:image/jpeg;base64,',

        objUrser: {},
        /*idCompania: null,*/
        objPermissions: {},

        limitsMs: {
          lower_limit: 0,
          higher_limit: 7,
        }
    },

    actions,
    getters,
    mutations,
})