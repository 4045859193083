/** 
 * @description Route Module Strata-Id
 */
import { prefix } from "@/config/prefix"
import operationView from "../operationsViews/router"
import chroma from "../gasEvaluation/router"
const pathFileManagement = '/file-management/:well_selected'
const pre = prefix
    /** 
     * @description route RealTime
     */
const routes = [{
        /** 
         * @description route RealTime
         */
        path: pre + '/real-time/:well_selected',
        name: 'RealTimeView',
        component: () =>
            import ( /* webpackChunkName: "RealTime" */ '../realTime/ui/RealTime.vue'),
        title: 'RealTimeView',
        meta: { requireAuth: true },
    },
    {
        /** 
         * @description route Operations
         */
        path: pre + '/operations/:well_selected',
        name: 'OperationsView',
        component: () =>
            import ( /* webpackChunkName: "Operations" */ '../operations/ui/Operations.vue'),
        title: 'OperationsView',
        meta: { requireAuth: true },
    },
    {
        /** 
         * @description route Radar
         */
        path: pre + '/radar/:well_selected',
        name: 'RadarView',
        component: () =>
            import ( /* webpackChunkName: "Radar" */ '../radar/ui/Radar.vue'),
        title: 'RadarView',
        meta: { requireAuth: true },
    },
    {
        /** 
         * @description route Survey
         */
        path: pre + '/survey/:well_selected',
        name: 'SurveyView',
        component: () =>
            import ( /* webpackChunkName: "Survey" */ '../survey/ui/Survey.vue'),
        title: 'SurveyView',
        meta: { requireAuth: true },
    },
    {
        /** 
         * @description route Time Depth View
         */
        path: pre + '/time-depth/:well_selected',
        name: 'TimeDepthView',
        component: () =>
            import ( /* webpackChunkName: "Time Depth View" */ '../timeDepth/ui/TimeDepth.vue'),
        title: 'TimeDepthView',
        meta: { requireAuth: true },
    },
    {
        /** 
         * @description route Formatiuon Evaluation
         */
        path: pre + "/formation-evaluation/:well_selected",
        name: "FormationEvaluation",
        component: () =>
            import ( /* webpackChunkName: "FormationEvaluation" */ "../formationEvaluation/ui/TabFormationEvaluation.vue"),
        title: "FormationEvaluation",
        meta: { requireAuth: true },
    },
    {
        /** 
         * @description route Witsml
         */
        path: pre + "/witsml/:well_selected",
        name: "WitsmlView",
        component: () =>
            import ( /* webpackChunkName: "Witsml" */ "../witsml/ui/TabWitsml.vue"),
        title: "Witsml",
        meta: { requireAuth: true },
    },
    {
        path: pre + pathFileManagement,
        name: 'FileManageWell',
        component: () =>
            import ( /* webpackChunkName: "FileManagement" */ '../fileManagement/ui/FileManageWell.vue'),
        title: 'FileManageWell',
        meta: { requireAuth: true },
    },
    {
        path: pre + "/xrf/:well_selected",
        name: 'XRFView',
        component: () =>
            import ( /* webpackChunkName: "XRF" */ '../xrf/ui/XRF.vue'),
        title: 'XRF',
        meta: { requireAuth: true },
    },
    ...operationView,
    ...chroma
]


export default routes