import {apiGetNotifications , apiUpdateNotification} from "../uriApi"
import { getHttp,putHttp} from '@/utils/http'


const useNotification = () =>{
    /**
     * @description
     * @param { Int } pageNumber
     * @param { Int } pageSize
     * @returns { Object } api response
     */
    const getListNotifications = async(pageNumber=1,pageSize=5) => {
        const response = await getHttp(apiGetNotifications(pageNumber,pageSize))
        return response
    }

    const updateNotification = async(id_notification) => {
        const response = await putHttp(`${apiUpdateNotification}`, id_notification)
        return response
    }


    return{
        getListNotifications,
        updateNotification,
    }
    
}

export default useNotification