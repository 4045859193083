<template>
  <div class="container">
    <div class="row">
      <div class="col-6 offset-3 text-center">
        <h1>404</h1>
      <p class="contet1">
        <strong>Link not found</strong>
      </p>
      <p class="contet2">
        Sorry, the provided web address could not be found. 
        Please check the URL or go back to the 
        <a href="/home" class="underline-hover text-decoration-none">homepage</a> 
        of <strong>ID-LINK.</strong>
      </p>
      <a href="#" class="logo logo-img-1x">
        <img width="32" height="32" class="img-fluid imgsty" title alt src="/img/id.ad138923.png">
      </a>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  /**
     * @property Component name
  */
  name: "PageNotFound"
};

</script>

<style scoped>

  .container {
    margin-top: 5vh;
    font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  h1{
    font-size:5vh;
    margin: 0px 0 25px 0;
  }

  p{
    margin:20px 0;
  }

  .contet2{
    font-size:1.3vh;
  }

  .contet1{
    font-size:1.5vh;
  }

  .underline-hover {
    color: #4a90b9;
    text-decoration: underline !important; 
  }
  .underline-hover:hover { 
    color: #747a86;
    text-decoration: none !important; 
  }

  @media (orientation: landscape) {
    h1{
      font-size:5vw;
    }
    .contet2{
      font-size: 1.3vw;;
    }
    .contet1{
      font-size:1.5vw;
    }

  }
</style>