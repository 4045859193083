/** 
 * @description route RealTime
 */
import { prefix } from "@/config/prefix"
const pre = prefix
const pathFileManagement = '/file-management'
    /** 
     * @description route RealTime
     */
const routes = [{
    path: pre + pathFileManagement,
    name: 'FileManagement',
    component: () =>
        import ( /* webpackChunkName: "FileManagement" */ '../ui/FileManagement.vue'),
    title: 'FileManagement',
    meta: { requireAuth: true },
}, ]

export default routes