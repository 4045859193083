export default {
    setWellInformation(state, payload) {
        state.WellInformation = payload
    },
    /**
     * @description Receives and sets the value of the payload in the vuex store.
     * @param { Any } state reference to the chartsML object of the store
     * @param { Object } payload
     */
    setObjChartsML(state, payload) {
        if (payload.length) {
            switch (payload[0].uid) {
                case 1:
                    state.chartsML.chart_1 = payload;
                    break;
                case 2:
                    state.chartsML.chart_2 = payload;
                    break;
                case 3:
                    state.chartsML.chart_3 = payload;
                    break;
                case 4:
                    state.chartsML.chart_4 = payload;
                    break;
                case 5:
                    state.chartsML.chart_5 = payload;
                    break;
                default:
                    break;
            }
        } else {
            state.chartsML = {
                chart_1: [],
                chart_2: [],
                chart_3: [],
                chart_4: [],
                chart_5: [],
            }
        }
    },
};