export default {
    /**
     * @description gets the chartsML value of the state
     */
    getWellInformation: state => state.WellInformation,

    getObjChartsML: state => state.chartsML,

    getIdPc: state => state.WellInformation?.id_pc,

    getIdWell: state => state.WellInformation?.id,
};