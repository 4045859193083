<template>
  <div>
    <header>
     <nav class="mx-auto main-nav">
       <div class="btn-menu">
         <label v-on:click="toggleMobileSidebar()"><i class="mdi mdi-menu"></i></label>
       </div>
       <div class="">
         <ul class="navbar-nav ml-auto  main-menu  ">
           <li class="nav-item item-menu">
             <router-link to="/home"  
               class="nav-link"
               exact-active-class="exact-active" >
               HOME
             </router-link>
           </li>
           <li class="nav-item item-menu">
             <router-link
               :to="{ name: 'SolutionsView' }"
               class="nav-link"
               exact-active-class="exact-active">
               SOLUTIONS
             </router-link>
           </li>
                 
           <li class="nav-item item-menu">
             <router-link
               :to="{ name: 'AboutView' }"
               class="nav-link"
               exact-active-class="exact-active">
               ABOUT
             </router-link>
           </li>
           <li class="nav-item item-menu">
             <router-link
               :to="{ name: 'ContactView' }"
               class="nav-link"
               exact-active-class="exact-active">
               CONTACT US
               </router-link>
           </li>
         </ul>
       </div>
   
       
         <a href="/" class="logo"><img src="@/assets/pictures/idlink1.png" style="max-width: 140px" alt="" /></a>
       
   
       <div class="social-media-login">
         <div class="iconos">
           <ul class="navbar-nav flex-row" >
             <li class="nav-item ">
               <a href="https://es-la.facebook.com/"><i class="icon-nav mdi mdi-facebook"></i></a> 
             </li>
             <li class="nav-item ">          
               <a href="https://www.whatsapp.com/?lang=es"><i class="icon-nav mdi mdi-whatsapp"></i></a> 
             </li>
             <li class="nav-item ">
               <a href="https://www.instagram.com/"><i class="icon-nav mdi mdi-instagram"></i></a>        
             </li>
             <li class="nav-item ">
               <a href="https://ec.linkedin.com/"><i class="icon-nav mdi mdi-linkedin"></i></a>      
             </li>
           </ul>        
         </div>
         <div class="cont-login">
           <span class="userLogin" v-if="$store.getters.getIdUser" >{{ $store.getters.getDataUser.first_name }} {{ $store.getters.getDataUser.last_name }}</span> 
           <router-link v-if="$store.getters.getIdUser" :to="{ name: 'AppLayoutView'} " style="position: relative;"
           class="btn btn-primary btn-dash">
               Dashboard
           </router-link>  
           <router-link v-else :to="{ name: 'LoginView'}" style="position: relative" class ="btn btn-primary btn-login" >
               <span class="mdi mdi-account"></span>
                 Login
           </router-link>
           <i clas="mdi mdi-account" style="color: white !important"> </i>
         </div>
       </div>
     </nav>
    </header>
   
    <!--	--------------->
   
   <div class="container-menu" :class="{showSidebar: showMobileSidebar}">
     <div class="cont-menu"  >
       
       <nav >
   
         <div class="user-menu">
            <span> Welcome!</span>
            <span class="label-menu" v-if="$store.getters.getIdUser" >{{ $store.getters.getDataUser.first_name }} {{ $store.getters.getDataUser.last_name }}</span> 
         </div>
         
         <ul class="ml-auto flex-column " >
   
           <li >
            <a class="nav-link-menu" v-on:click="navigateTo('AppLayoutPublic')">HOME</a>
           </li>
           <li class="nav-item item-menu"  >
            <a class="nav-link-menu" v-on:click="navigateTo('SolutionsView')">SOLUTIONS</a>
           </li> 
           <li >
            <a class="nav-link-menu" v-on:click="navigateTo('AboutView')">ABOUT</a>
           </li>
           <li >
            <a class="nav-link-menu" v-on:click="navigateTo('ContactView')">CONTACT US</a>
           </li>
           <li >
            <a class="nav-link-menu" v-if="$store.getters.getIdUser" v-on:click="navigateTo('AppLayoutView')">DASHBOARD</a>
            <a class="nav-link-menu" v-else v-on:click="navigateTo('LoginView')">LOGIN</a>
           </li>
         </ul>
       </nav>
       <label v-on:click="toggleMobileSidebar()">✖️</label>
     </div>
   </div>
  </div>
   </template>
   <script>
    export default {
    name: "NavigationView",
    data: () => ({
      showMobileSidebar: false
    }),
    methods: {
      toggleMobileSidebar() {
        this.$data.showMobileSidebar = !this.$data.showMobileSidebar;
      },
      navigateTo(pageName) {
        this.$router.push({ name: pageName });
        this.$data.showMobileSidebar = false;
      }
    }
  };
   </script>
   <style scoped>
   *{
     margin: 0px;
     padding: 0px;
     box-sizing: border-box;
   }
     header{
       display: inline-block;
       width: 100%;
       padding: 15px;
       position: relative;   
     }
   
     nav{
       
       display: flex;
       margin-left: 0px;
       justify-content: space-between;
       align-items: center;
       position: relative;
     }
   
      .logo{  
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       position: absolute;
       margin: auto;
       width: min-content;
       height: min-content;
     }
   

    .router-link-active {
      color: rgb(28, 127, 204) !important;
      transform: scale(1.1);
      font-weight: bold;  
      
    }
   
    .social-media-login{
      display: flex;
      align-items: center;
     }
     .iconos{
       margin-right: 10px;
     }
   
     .cont-login{
      display: flex;
      align-items: center;
     }
       
     ul li a i { 
       padding: 0px 10px;
       line-height: 40px;
     }
   
     .userLogin{
       font-size: 1rem;
       font-weight: bold;
       text-transform: uppercase;
     }
     .nav-link{
       font-size: 1rem;
       padding: 5px 15px;
       font-weight: bold;
       display: block;
     }
   
     nav ul li:hover,.icon-nav:hover, .nav ul li.active, .icon-nav.active{
       color: rgb(28, 127, 204);
       transform: scale(1.2);
      
     }
   
      .btn-login{
       margin-left: 20px;
       padding: 8px 15px;
     }
   
     .btn-dash{
       
       margin-left: 20px;
       padding: 5px 15px;
     }
   
     .main-menu{
       flex-direction: row;
     }
   
     .icon-nav{
        font-size: 1.6rem;
     }
   
     
     .container-menu , .btn-menu, #btn-menu{display: none;}

     @media screen and ( max-width: 1100px)
     {
      .nav-link{
       padding: 5px 10px;
      }

      .logo img{
        width :100px
      }
      
     }
   
     @media screen and (max-width:991px) {
       .container-menu{display: block;}
       .main-menu, .icon-nav, .btn-dash, .btn-login, .userLogin{display: none;}
   
       label{
         font-size: 2rem;
       }

       .btn-menu{
         display: block;
         float: left;
         line-height:40px;
   
       }
   
       .container .btn-menu label{
       color: #fff;
       font-size: 25px;
       cursor: pointer;
     }
   
       /*Menù lateral*/
     #btn-menu{
       display: none;
     }
     .container-menu{
       position: absolute;
       width: 100%;
       height: 100vh;
       top: 0;left: 0;
       transition: all 500ms ease;
       opacity: 1;
       visibility: hidden;
     }
     .container-menu.showSidebar{
       opacity: 1;
       visibility: visible;
     }
   
     .capa.showSidebar{
       background: rgba(0,0,0,0.6);
     }
     
     
     .cont-menu{
      padding: 0 20px;
       width: 100%;
       max-width: 250px;
       background: #1c1c1c;
       height: 100vh;
       position: relative;
       transition: all 500ms ease;
       transform: translateX(-100%);
     }
     .container-menu.showSidebar .cont-menu{
       transform: translateX(0%);
     }
     .cont-menu nav{
       transform: translateY(15%);
       display: block;
     }
   
     .cont-menu nav ul {
       margin-top: 25px;
     }
     .cont-menu nav ul li{
       list-style-type: none;
     }
     .nav-link-menu{
       display: block;
       text-decoration: none;
       padding: 20px;
       color: #c7c7c7;
       font-size: 1rem;
       font-weight: bold;
       border-left: 5px solid transparent;
       transition: all 400ms ease;
     }
     .cont-menu nav ul li:hover, .cont-menu nav ul li.active{
       border-left: 5px solid #1657b1;
       transform: scale(1);
       color: rgb(14, 135, 227) !important;
     }

     
     .cont-menu label{
       position: absolute;
       right: 5px;
       top: 10px;
       color: #fff;
       cursor: pointer;
       font-size: 18px;
     }
   
     .label-menu{
       display: block;
       margin-left: 20px;
       margin-top: 20px; 
       color: #2eb6e7;
       border-bottom: #fff;
       text-transform: uppercase;
       font-size: 1.1rem;
       font-weight: bold;
     }
     .user-menu{
       padding: 0%;
       display: block;
       padding-bottom: 10px;
       border-bottom: 0.3px solid #fff;
     }
     /*Fin de Menù lateral*/
     }

    
   
   </style>