export default {
    /**
     * @description commit to the mutation setAuthenticateDataUser
     * @param {any} state reference to the mutation setAuthenticateDataUser
     * @param {any} payload get data
     */
    storeAuthenticateDataUser: function(state, payload) {
        state.commit('setAuthenticateDataUser', payload)
    },

    /**
     * @description reads the token from local storage and executes the setTokenAuth mutation with the token or null.
     * @param {any} commit execute to the mutation setTokenAuth
     */
    readToken: function(state, payload) {
        state.commit('setTokenAuth', payload)
        //localStorage.getItem('keyToken') ? commit('setTokenAuth', localStorage.getItem('keyToken')) : commit('setTokenAuth', null)
    },

    refreshToken: function(state, payload) {
        state.commit('setRefreshToken', payload)
    },

    storeObjPermissions: function(state, payload) {
        state.commit('setObjPermissions', payload)
    },



};