/**
 * @description route User
 */
const pathUser = "/user";
const pathProfile = "/profile-user";
const routes = [{
    path: pathUser,
    name: "UserView",
    component: () =>
        import ( /* webpackChunkName: "UserView" */ "../ui/UserList.vue"),
    title: "UserView",
    meta: {
        requireAuth: true,
        roles: [],
        permissions: ['_viewer_user_company']
    },
}, 
{
    path: pathProfile,
    name: "ProfileUser",
    component: () =>
        import ( /* webpackChunkName: "ProfileUser" */ "../ui/ProfileUser.vue"),
    title: "ProfileUser",
},
];

export default routes;