//POST
export const apiCompany = '/api/company'

export const apiUpdateCompany = '/api/company/' //{id_company}

export const apiCompanyUser = '/api/company-user'

//GET
export const apiGetCompany = '/api/company'

//GET
export const apiGetTypeCompany = '/api/company-type'

//GET
export const apiGetTypeParent = 'api/company/by-type-parent/'

//GET
export const apiGetCountries = '/api/location/getCountries'

//GET
export const apiGetRole = '/api/role/by-companytype/'