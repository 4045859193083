<template>
    <section class="app-sidebar fixed-size-sidebar">
        <nav class="sidebar sidebar-offcanvas sidebar-main scrollable-y"  id="sidebar">
            <div class="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top text-center" style="background: #080f19;">
                <router-link class="sidebar-brand brand-logo" style="background: rgb(8 15 25);" to="/">
                    <img src="@/assets/pictures/idlink1.png" alt="logo" />
                </router-link>
                <router-link class="sidebar-brand brand-logo-mini" style="background: rgb(8 15 25);" to="/id-link">
                    <img src="@/assets/pictures/id.png" alt="logo" />
                </router-link>
            </div>
            <ul class="nav">
                <DropdownSidebar
                @click="toggleMenu"
                >
                </DropdownSidebar>
                <li class="nav-item nav-category">
                    <span class="nav-link">My Company</span>
                    <span class="menu-title">{{$store.getters.getDataUser?.company?.name }}</span>
                </li>
                <hr>
                <li class="nav-item nav-category">
                    <span class="nav-link">Navigation</span>
                </li>
                <li v-if="completeRoles.includes($store.getters.getDataUserRoleAbbreviation)" class="nav-item menu-items" >
                    <router-link class="nav-link" :to="{name:'AppLayoutView'}" exact-active-class="exact-active">
                        <span class="menu-icon">
                            <i class="mdi mdi-view-dashboard"></i>
                        </span>
                        <span class="menu-title menu-title-principal">My Dashboard</span>
                    </router-link>
                </li>
                <template>
                    <li v-if="roleAdmin.includes($store.getters.getDataUserRoleAbbreviation)" class="nav-item menu-items" >
                        <router-link class="nav-link" to="/profile" exact-active-class="exact-active">
                            <span class="menu-icon">
                                <i class="mdi mdi-account"></i>
                            </span>
                            <span class="menu-title menu-title-principal">Profile</span>
                        </router-link>
                    </li>
                </template>
                <div>
                    <li class="nav-item menu-items compose-menu">
                        <div class="nav-link " data-toggle="collapse"  @click="isActive = !isActive">
                          <span class="menu-icon">
                            <i class="mdi mdi-city"></i>
                          </span>
                          <span class="menu-title menu-title-principal">Company</span>
                          <i class="icon-mdi mdi mdi-menu-down"></i>
                        </div>
                        <div :class="[isActive ? 'show' : '']" class="collapse" id="auth">
                            <ul class="nav flex-column sub-menu">
                                <span class="submenu-title">Company</span>
                                <div>
                                    <li v-if="roleAdminCompany.includes($store.getters.getDataUserRoleAbbreviation)" class="nav-item menu-items">
                                        <router-link class="nav-link" :to="{name:'CompanyView'}" exact-active-class="exact-active">
                                            <span class="menu-icon">
                                                <i class="mdi mdi-city"></i>
                                            </span>
                                            <span class="menu-title">Companies List</span>
                                        </router-link>
                                    </li>
                                </div>
                                <div>
                                    <li v-if="roleAdminCompany.includes($store.getters.getDataUserRoleAbbreviation)" class="nav-item menu-items">
                                        <router-link class="nav-link" :to="{name:'AssignWellToCompany'}" exact-active-class="exact-active">
                                            <span class="menu-icon">
                                                <i class="mdi mdi-tower-beach"></i>
                                            </span>
                                            <span class="menu-title">Assign wells</span>
                                        </router-link>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </li>
                </div>
                <div>
                    <li class="nav-item menu-items compose-menu">
                        <div class="nav-link" data-toggle="collapse" @click="isActive1 = !isActive1">
                          <span class="menu-icon">
                            <i class="mdi mdi-account-multiple"></i>
                          </span>
                          <span class="menu-title menu-title-principal">Users</span>
                          <i class="icon-mdi mdi mdi-menu-down"></i>
                        </div>
                        <div :class="[isActive1 ? 'show' : '']" class="collapse" id="auth">
                            <ul class="nav flex-column sub-menu">
                                <span class="submenu-title">Users</span>
                                <div>
                                    <li v-if="roleAdmin.includes($store.getters.getDataUserRoleAbbreviation)" class="nav-item menu-items" >
                                        <router-link class="nav-link" :to="{name:'UserView'}" exact-active-class="exact-active">
                                            <span class="menu-icon">
                                                <i class="mdi mdi-account-multiple"></i>
                                            </span>
                                            <span class="menu-title">Users List</span>
                                        </router-link>
                                    </li>
                                </div>
                                <div>
                                    <li v-if="roleAdmin.includes($store.getters.getDataUserRoleAbbreviation)" class="nav-item menu-items">
                                        <router-link class="nav-link" :to="{name:'AssignWellToUsers'}" exact-active-class="exact-active">
                                            <span class="menu-icon">
                                                <i class="mdi mdi-tower-beach"></i>
                                            </span>
                                            <span class="menu-title">Assign wells</span>
                                        </router-link>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </li>
                </div>
                <li class="nav-item nav-category">
                    <span class="nav-link">Configurations</span>
                </li>
                <li class="nav-item menu-items" >
                    <router-link class="nav-link" :to="{name:'ConfigurationVariables'}" exact-active-class="exact-active">
                        <span class="menu-icon">
                            <i class="mdi mdi-multiplication-box"></i>
                        </span>
                        <span class="menu-title menu-title-principal">Variables</span>
                    </router-link>
                </li>
                <li class="nav-item nav-category">
                    <span class="nav-link">Reports</span>
                </li>
                <li class="nav-item menu-items" >
                    <router-link class="nav-link" :to="{name:'FileManagement'}" exact-active-class="exact-active">
                        <span class="menu-icon">
                            <i class="mdi mdi-chart-areaspline"></i>
                        </span>
                        <span class="menu-title menu-title-principal">File Management</span>
                    </router-link>
                </li>
                <li class="nav-item nav-category">
                    <span class="nav-link">Alarm/Alert</span>
                </li>
                <li class="nav-item menu-items" >
                    <router-link class="nav-link" :to="{name:'DrillSage'}" exact-active-class="exact-active">
                        <span class="menu-icon">
                            <i class="mdi mdi-chart-areaspline"></i>
                        </span>
                        <span class="menu-title menu-title-principal">DrillSage</span>
                    </router-link>
                </li>
                
            </ul>
        </nav>
    </section>
</template>

<script>
import DropdownSidebar from "./DropdownSidebar.vue";
import { completeRoles, roleAdmin, roleOwner ,roleAdminCompany} from '../../modules/administration/rolesPermissions/params/roles'

export default {
    name: "SidebarView",
    components: {
        DropdownSidebar,
    },
    data() {
        return {
            collapses: [{ show: false }, { show: false }, { show: false }],
            isActive:false,
            isActive1:false,
            completeRoles,
            roleAdmin,
            roleAdminCompany,
            roleOwner,
        };
    },

    mounted() {
        const body = document.querySelector("body");
        // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
            el.addEventListener("mouseover", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.add("hover-open");
                }
            });
            el.addEventListener("mouseout", function () {
                if (body.classList.contains("sidebar-icon-only")) {
                    el.classList.remove("hover-open");
                }
            });
        });
    },
    methods: {
        collapseAll() {
            var exp_element = document.getElementsByClassName("show");
            if (exp_element.length > 0) {
                var elm_id = exp_element[0].id;
                this.$root.$emit("bv::toggle::collapse", elm_id);
            }
        },
        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input];
            return paths.some((path) => {
                return this.$route.path.indexOf(path) === 0; // current path starts with this path string
            });
        },
    },
    watch: {
        $route() {
            document.querySelector("#sidebar").classList.remove("active");
        },
    },
};
</script>
<style scoped>
    .sidebar {
        height: 100%;
        margin-left: 2px;
    }
    .page-body-wrapper {
        width: auto !important;
    }
    .sidebar-main {
        background-image: url("@/assets/pictures/fondoLateral.jpg");
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.787);
        background-blend-mode: soft-light;
        position: fixed;
        flex-direction: column;
        display: flex;
    }


    .router-link-active {
        color: white !important;
        background: #0b3c5d;
    }
</style>
